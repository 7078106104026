const APP_CONFIG = {};
const host = window.location.hostname || window.location.host;
const devGA4 = 'G-YJHHTM92QW';
const stagingGA4 = 'G-47FT9WWYZE';
const prodGA4 = 'G-P0BZEF9NC4';
const devClarity = 'hhmrsmg59q';
const prodClarity = 'h1nx3a1z8l';

const {
  REACT_APP_S3_BUCKET_URL,
  REACT_APP_S3_BUCKET_URL_WITHOUT_REGION,
  REACT_APP_CLOUDFRONT_URL,
} = process.env;

console.log('VARS', {
  REACT_APP_S3_BUCKET_URL,
  REACT_APP_S3_BUCKET_URL_WITHOUT_REGION,
  REACT_APP_CLOUDFRONT_URL,
});

//DEV
if (host.includes('dev') || host.includes('localhost')) {
  APP_CONFIG.IMAGE_URL = 'https://dev-api.2wheelr.com/';
  APP_CONFIG.NODE_URL = 'https://dev-api.2wheelr.com/';
  APP_CONFIG.cashfreeMode = 'sandbox'; /* sandbox OR production */
  APP_CONFIG.GA4_TRACKING_ID = devGA4;
  APP_CONFIG.CLARITY_PROJECT_ID = devClarity;
  APP_CONFIG.SITE_URL = 'https://www.dev.2wheelr.com';
  APP_CONFIG.S3_BUCKET_URL = REACT_APP_S3_BUCKET_URL;
  APP_CONFIG.S3_BUCKET_URL_WITHOUT_REGION = REACT_APP_S3_BUCKET_URL_WITHOUT_REGION;
  APP_CONFIG.CLOUDFRONT_URL = REACT_APP_CLOUDFRONT_URL;
}
//RELEASE
else if (host.includes('release') || host.includes('staging.2wheelr')) {
  APP_CONFIG.IMAGE_URL = 'https://staging-api.2wheelr.com/';
  APP_CONFIG.NODE_URL = 'https://staging-api.2wheelr.com/';
  APP_CONFIG.cashfreeMode = 'sandbox'; /* sandbox OR production */
  APP_CONFIG.GA4_TRACKING_ID = stagingGA4;
  APP_CONFIG.CLARITY_PROJECT_ID = devClarity;
  APP_CONFIG.SITE_URL = 'https://www.dev.2wheelr.com';
  APP_CONFIG.S3_BUCKET_URL = REACT_APP_S3_BUCKET_URL;
  APP_CONFIG.S3_BUCKET_URL_WITHOUT_REGION = REACT_APP_S3_BUCKET_URL_WITHOUT_REGION;
  APP_CONFIG.CLOUDFRONT_URL = REACT_APP_CLOUDFRONT_URL;
} else if (host.includes('qa.2wheelr')) {
  APP_CONFIG.IMAGE_URL = 'https://dev-api.2wheelr.com/';
  APP_CONFIG.NODE_URL = 'https://dev-api.2wheelr.com/';
  APP_CONFIG.cashfreeMode = 'sandbox'; /* sandbox OR production */
  APP_CONFIG.GA4_TRACKING_ID = devGA4;
  APP_CONFIG.CLARITY_PROJECT_ID = devClarity;
  APP_CONFIG.SITE_URL = 'https://www.dev.2wheelr.com';
  APP_CONFIG.S3_BUCKET_URL = REACT_APP_S3_BUCKET_URL;
  APP_CONFIG.S3_BUCKET_URL_WITHOUT_REGION = REACT_APP_S3_BUCKET_URL_WITHOUT_REGION;
  APP_CONFIG.CLOUDFRONT_URL = REACT_APP_CLOUDFRONT_URL;
}
//PROD https://www.2wheelr.com/
else if (
  host === 'www.2wheelr.com' ||
  host.includes('2wheelr-portal.vercel') ||
  host.includes('master')
) {
  APP_CONFIG.SITE_URL = 'https://www.2wheelr.com';
  APP_CONFIG.IMAGE_URL = 'https://api.2wheelr.com/';
  APP_CONFIG.NODE_URL = 'https://api.2wheelr.com/';
  APP_CONFIG.cashfreeMode = 'production'; /* sandbox OR production */
  APP_CONFIG.GA4_TRACKING_ID = prodGA4;
  APP_CONFIG.CLARITY_PROJECT_ID = prodClarity;
  APP_CONFIG.SITE_URL = 'https://www.2wheelr.com';
  APP_CONFIG.S3_BUCKET_URL = REACT_APP_S3_BUCKET_URL;
  APP_CONFIG.S3_BUCKET_URL_WITHOUT_REGION = REACT_APP_S3_BUCKET_URL_WITHOUT_REGION;
  APP_CONFIG.CLOUDFRONT_URL = REACT_APP_CLOUDFRONT_URL;
} else {
  APP_CONFIG.IMAGE_URL = 'https://dev-api.2wheelr.com/';
  APP_CONFIG.NODE_URL = 'https://dev-api.2wheelr.com/';
  APP_CONFIG.cashfreeMode = 'sandbox'; /* sandbox OR production */
  APP_CONFIG.GA4_TRACKING_ID = devGA4;
  APP_CONFIG.CLARITY_PROJECT_ID = devClarity;
  APP_CONFIG.SITE_URL = 'https://www.dev.2wheelr.com';
  APP_CONFIG.S3_BUCKET_URL = REACT_APP_S3_BUCKET_URL;
  APP_CONFIG.S3_BUCKET_URL_WITHOUT_REGION = REACT_APP_S3_BUCKET_URL_WITHOUT_REGION;
  APP_CONFIG.CLOUDFRONT_URL = REACT_APP_CLOUDFRONT_URL;
}

export { APP_CONFIG };
