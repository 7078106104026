import { combineReducers } from 'redux';
import CommonReducer from '../store/common/commonReducer';
import SellBikeReducer from '../store/sellBike/sellbikeReducer';
import UserDashboardReducer from '../store/userDashboard/userDashboardReducer';
import ViewBikeReducer from '../store/viewBike/viewbikeReducer';
import AuthReducer from '../store/auth/authReducer';
import SellOderReducer from '../store/myAccount/SellOrder/reducer';
import BookingsReducer from '../store/myAccount/Bookings/reducer';
import CartReducer from '../store/myAccount/Cart/reducer';
const rootReducer = combineReducers({
  common: CommonReducer,
  sellbike: SellBikeReducer,
  buyBikes: ViewBikeReducer,
  userDashboard: UserDashboardReducer,
  auth: AuthReducer,
  sellOrder: SellOderReducer,
  bookings: BookingsReducer,
  cart: CartReducer,
});
export default rootReducer;
