import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import Toast from './js/common/toast';
import { connect } from 'react-redux';
import Footer from './js/component/footer';
import Header from './js/component/header';
import { getToast, getOverlay, getDialog } from './js/store/common/selectors';
import LoadingOverlay from './js/common/overlay';
import DialogComponent from './js/common/dialog';
import GlobalStyles from './js/hooks/globalStyles';
import ScrollToTop from './js/scrollToTop';

const Home = lazy(() => import('./js/container/home'));
const Login = lazy(() => import('./js/container/auth'));
const ThankYouScreen = lazy(() => import('./js/component/thankYou'));
const SellBike = lazy(() => import('./js/container/sellBike'));
const contactUs = lazy(() => import('./js/component/contactUs'));
const ViewAllBike = lazy(() => import('./js/container/viewAllBike'));
const ViewBikeDetail = lazy(() => import('./js/container/viewBikeDetail'));
const BookTestRide = lazy(() => import('./js/container/bookTestRide'));
const BookTestRidePayment = lazy(() =>
  import('./js/container/bookTestRidePayment')
);
const Cart = lazy(() => import('./js/container/myAccount/myCart'));
const BookTestRideSuccess = lazy(() =>
  import('./js/container/bookTestRideSuccess')
);
const ComingSoon = lazy(() =>
  import('./js/container/myAccount/myAccountMenu/ComingSoon')
);
const PrivacyPolicy = lazy(() => import('./js/component/privacyPolicy'));
const RefundPolicy = lazy(() => import('./js/component/refundPolicy'));
const ShippingPolicy = lazy(() => import('./js/component/shippingPolicy'));
const TermsConditions = lazy(() => import('./js/component/termsConditions'));
const AboutUs = lazy(() => import('./js/component/about'));
const MySellOrders = lazy(() =>
  import('./js/container/myAccount/mySellOrders')
);
const Wishlist = lazy(() => import('./js/container/wishlist'));

const MyBookings = lazy(() => import('./js/container/myAccount/myBookings'));

const MyProfile = lazy(() => import('./js/container/myAccount/myProfile'));
const AppRouter = (props) => {
  const { toast, overlay, dialog } = props;
  return (
    <>
      {toast?.status && <Toast />}
      {<GlobalStyles />}
      {overlay && <LoadingOverlay />}
      {dialog && <DialogComponent />}
      <Router>
        <ScrollToTop />
        {true && <Header />}
        <Suspense fallback={<LoadingOverlay fallback={true} />}>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/login/verify-otp" component={Login}></Route>
          <Route exact path="/sell-bike" component={SellBike}></Route>
          <Route exact path="/contact-us" component={contactUs}></Route>
          <Route
            exact
            path="/sell-bike/select-criteria/:id"
            component={SellBike}
          ></Route>
          <Route
            exact
            path="/sell-bike/upload-document/:id"
            component={SellBike}
          ></Route>
          <Route
            exact
            path="/sell-bike/upload-image/:id"
            component={SellBike}
          ></Route>
          <Route exact path="/view-bike" component={ViewAllBike}></Route>
          <Route
            exact
            path="/view-bike?filter=:filter"
            component={ViewAllBike}
          ></Route>
          <Route
            exact
            path="/view-bike?sort=:sort"
            component={ViewAllBike}
          ></Route>
          <Route exact path="/view-bike/:id" component={ViewBikeDetail}></Route>
          <Route exact path="/my-account/profile" component={MyProfile}></Route>
          <Route
            exact
            path="/my-account/sell-order"
            component={MySellOrders}
          ></Route>
          <Route
            exact
            path="/book-test-ride/:id"
            component={BookTestRide}
          ></Route>
          <Route exact path="/rto-services" component={ComingSoon}></Route>
          <Route exact path="/help-support" component={ComingSoon}></Route>
          <Route exact path="/finance" component={ComingSoon}></Route>
          <Route
            exact
            path="/book-test-ride/payment/:id"
            component={BookTestRidePayment}
          ></Route>
          <Route
            exact
            path="/buy-bike/payment/:id"
            component={BookTestRidePayment}
          ></Route>
          <Route
            exact
            path="/book-test-ride/success/:id/:event_id"
            component={BookTestRideSuccess}
          ></Route>
          <Route
            exact
            path="/buy-bike/success"
            component={ThankYouScreen}
          ></Route>
          <Route exact path="/my-account/bookings" component={MyBookings} />
          <Route
            exact
            path="/my-account/bookings/:page"
            component={MyBookings}
          />
          <Route exact path="/my-account/cart" component={Cart} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route>
          <Route exact path="/refund-policy" component={RefundPolicy}></Route>
          <Route exact path="/shipping-policy" component={ShippingPolicy}></Route>
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsConditions}
          ></Route>
          <Route exact path="/about-us" component={AboutUs}></Route>
          <Route exact path="/wishlist" component={Wishlist}></Route>
          <Route exact path="/wishlist?sort=:sort" component={Wishlist}></Route>
          <Switch>
            <Redirect from="/pages/sell-my-bike" to="/sell-bike" />
            <Redirect from="/pages/sell-your-bike" to="/sell-bike" />
          </Switch>
        </Suspense>
        {true && <Footer />}
      </Router>
    </>
  );
};

const mapSateToProps = (state) => {
  return {
    toast: getToast(state),
    overlay: getOverlay(state),
    dialog: getDialog(state),
  };
};

export default connect(mapSateToProps, {})(React.memo(AppRouter));
