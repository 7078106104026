import { grey } from "@material-ui/core/colors";

const styles = () => {
  return {
    center: {
      position: 'fixed',
      top: '47%',
      left: '47%',
    },
    overlay: {
      position: 'fixed',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      background: grey[900],
      opacity: '0.5',
      zIndex: 10000,
    },
    fallback: {
      margin: '400px 0px 400px 0px'
    }
  };
};
export default styles;
