import { createSelector } from 'reselect';

const CommonReducerSelector = (state) => state.common;

export const getToast = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.toast;
  }
);

export const getDialog = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.dialog;
  }
);

export const getProductServiceFullDisplayDialog = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.productServiceDialog;
  }
);

export const getOverlay = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.overlayStatus;
  }
);

export const getComponentOverlay = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.componentOverlayStatus;
  }
);

export const loggedInUser = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.userDetails;
  }
);
export const getCities = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.cities;
  }
);

export const getSelectionCity = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.selection_cities;
  }
);

export const getTopBrand = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.topBrands;
  }
);

export const getFaqsSelector = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.faqs;
  }
);

export const getContactUsStatus = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.contactUsMsg;
  }
);

export const getTestimonials = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.testimonials;
  }
);

export const getHowYouGotAboutUs = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.contactHowYouGotAboutUs;
  }
);

export const getRtoServices = createSelector(
  [CommonReducerSelector],
  (CommonReducer) => {
    return CommonReducer.contactRtoServices;
  }
);