/* eslint-disable indent */
import {
  SET_OVERLAY,
  SET_LOGGED_IN_USER,
  MANAGE_DIALOG,
  MANAGE_TOAST,
  SET_COMPONENT_OVERLAY,
  SET_FAQ,
  SET_CITYLIST,
  SET_TOP_BRANDS,
  SET_CONTACT_US_MESSAGE,
  SET_TESTIMONIALS,
  SET_HOW_YOU_GOT_ABOUT_US,
  SET_RTO_SERVICES,
  SELCTION_CITY,
} from '../../actions/actionTypes';
import { faqs } from '../../data/staticAppData';
import * as utils from '../../axiosUtils/appUtils';

export const setOverlayStatus = (data) => {
  return {
    type: SET_OVERLAY,
    payload: data,
  };
};

export const setComponentOverlay = (data) => {
  return {
    type: SET_COMPONENT_OVERLAY,
    payload: data,
  };
};

export const setLoggedInUser = (data) => {
  return {
    type: SET_LOGGED_IN_USER,
    payload: data,
  };
};

export const manageToast = (data) => {
  return {
    type: MANAGE_TOAST,
    payload: data,
  };
};

export const manageDialog = (data) => {
  return {
    type: MANAGE_DIALOG,
    payload: data,
  };
};

export const setFaq = (data) => {
  return {
    type: SET_FAQ,
    payload: data,
  };
};

export const getFaqs = () => {
  return (dispatch) => {
    dispatch(setFaq(faqs));
  };
};

export const setCityList = (data) => {
  return {
    type: SET_CITYLIST,
    payload: data,
  };
};

export const setSelectionCityList = (data) => {
  return {
    type: SELCTION_CITY,
    payload: data,
  };
};

export const setTopBrands = (data) => {
  return {
    type: SET_TOP_BRANDS,
    payload: data,
  };
};

export const setContactUsMsg = (data) => {
  return {
    type: SET_CONTACT_US_MESSAGE,
    payload: data,
  };
};

export const setTestimonials = (data) => {
  return {
    type: SET_TESTIMONIALS,
    payload: data,
  };
};

export const setHowYouGotAboutUs = (data) => {
  return {
    type: SET_HOW_YOU_GOT_ABOUT_US,
    payload: data,
  };
};

export const setRTOServicesList = (data) => {
  return {
    type: SET_RTO_SERVICES,
    payload: data,
  };
};

export const getCityList = () => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi('city')
      .then((response) => {
        dispatch(setCityList(response?.geography_city));
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getSelectionCityList = () => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi('city/store')
      .then((response) => {
        dispatch(setSelectionCityList(response?.listing_store));
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getTopBrands = () => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi('make/get-top-make')
      .then((response) => {
        dispatch(setTopBrands(response?.vehicle_config_make));
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getStatistics = (testData) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi('other/get-statistics')
      .then((response) => {
        let setData = {
          ...response?.config_statistics[0], testimonial: testData?.settings_testimonial || []
        }
        dispatch(setTestimonials(setData));
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getTestimonials = () => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi('other/get-testimonials')
      .then((response) => {
        dispatch(getStatistics(response));
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getHowYouGotAboutList = () => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi('other/channel-type')
      .then((response) => {
        dispatch(setHowYouGotAboutUs(response));
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getRTOServicesList = () => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi('other/rto-services')
      .then((response) => {
        dispatch(setRTOServicesList(response));
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};


export const setContactUsDetails = (data) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .postNodeApi('listing/lead/create', data)
      .then((response) => {
        dispatch(setContactUsMsg(true));
        dispatch(setOverlayStatus(false));
        setTimeout(() => {
          dispatch(setContactUsMsg(false));
        }, 5000);
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};
