import {
  SET_SELLBIKE_SEARCH_CRITERIA,
  SET_RECOMMENDATION_BIKE_DATA,
  SET_BRAND_DATA,
  SET_SELECTED_BRAND,
  SET_SELECTED_VARIENT,
  SET_SELECTED_MODEL,
  SET_VARIENT_DATA,
  SET_MODEL_DATA,
  SET_SELECTED_BIKE,
  SET_SELECTED_YEAR,
  SET_SELECTED_KM_DRIVEN,
  SET_SELECTED_OWNER_TYPE,
  SET_OWNER_TYPE_DATA,
  SET_KMDRIVEN_DATA,
  SET_YEAR_DATA,
  SET_SELLBIKE_DOCUMENTS,
  RESET_INITIAL_STATE,
  SET_PROVISIONAL_PRICE,
  SET_MOVE_TAB,
} from '../../actions/actionTypes';
import { recommendationBikeData } from '../../data/staticAppData';
import * as utils from '../../axiosUtils/appUtils';
import { manageToast, setOverlayStatus } from '../common/actions';

export const setSellbikeSearchCriteriaData = (data) => {
  return {
    type: SET_SELLBIKE_SEARCH_CRITERIA,
    payload: data,
  };
};

export const setBrandsData = (data) => {
  return {
    type: SET_BRAND_DATA,
    payload: data,
  };
};

export const setModelData = (data) => {
  return {
    type: SET_MODEL_DATA,
    payload: data,
  };
};

export const setVarientData = (data) => {
  return {
    type: SET_VARIENT_DATA,
    payload: data,
  };
};

export const setkmData = (data) => {
  return {
    type: SET_KMDRIVEN_DATA,
    payload: data,
  };
};

export const setYearsData = (data) => {
  return {
    type: SET_YEAR_DATA,
    payload: data,
  };
};

export const setOwnerTypeData = (data) => {
  return {
    type: SET_OWNER_TYPE_DATA,
    payload: data,
  };
};

export const setSelectedBrandsData = (data) => {
  return {
    type: SET_SELECTED_BRAND,
    payload: data,
  };
};

export const setSelectedVarientData = (data) => {
  return {
    type: SET_SELECTED_VARIENT,
    payload: data,
  };
};

export const setSelectedModelData = (data) => {
  return {
    type: SET_SELECTED_MODEL,
    payload: data,
  };
};

export const setSelectedYearsData = (data) => {
  return {
    type: SET_SELECTED_YEAR,
    payload: data,
  };
};

export const setSelectedKmDrivenData = (data) => {
  return {
    type: SET_SELECTED_KM_DRIVEN,
    payload: data,
  };
};

export const setSelectedOwnerType = (data) => {
  return {
    type: SET_SELECTED_OWNER_TYPE,
    payload: data,
  };
};

export const setMoveTab = (data) => {
  return {
    type: SET_MOVE_TAB,
    payload: data,
  };
};

export const setRecommendedBikeData = (data) => {
  return {
    type: SET_RECOMMENDATION_BIKE_DATA,
    payload: data,
  };
};

export const setSelectedBikeData = (data) => {
  return {
    type: SET_SELECTED_BIKE,
    payload: data,
  };
};

export const setSellBikeDocuments = (data) => {
  return {
    type: SET_SELLBIKE_DOCUMENTS,
    payload: data,
  };
};

export const setProvisionalPrice = (data) => {
  return {
    type: SET_PROVISIONAL_PRICE,
    payload: data,
  };
};

export const resetBikeData = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};

export const getSearchBrandData = (data) => {
  return (dispatch) => {
    //dispatch(setOverlayStatus(true));
    dispatch(setSellbikeSearchCriteriaData([]));
    utils
      .getNodeApi(`bike?q=${data}`)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        dispatch(setSellbikeSearchCriteriaData(response));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getBrandData = () => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi('make')
      .then((response) => {
        dispatch(setOverlayStatus(false));
        response.vehicle_config_make = response?.vehicle_config_make.map((sellbikedata) => ({
          label: sellbikedata?.brand_name,
          brand_logo: sellbikedata?.brand_logo,
          id: sellbikedata?.id,
        }));
        if (response?.vehicle_config_make?.length === 1) {
          const brandData = {
            id: response?.vehicle_config_make[0]?.id,
            brand_logo: response?.vehicle_config_make[0]?.brand_logo,
            label: response?.vehicle_config_make[0]?.label,
          };
          dispatch(setMoveTab(1));
          dispatch(getModelData(brandData.id));
          dispatch(setSelectedBrandsData(brandData));
        }
        dispatch(setBrandsData(response?.vehicle_config_make));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getStaticData = (bikeData = null) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi('general/get-sell-page-enum')
      .then((response) => {
        response.config_kms_driven = response?.config_kms_driven.map((sellbikedata) => ({
          label: sellbikedata?.value,
          id: sellbikedata?.id,
        }));
        // response.config_make_year = response?.config_make_year.map(
        //   (sellbikedata) => ({
        //     label: sellbikedata?.value,
        //     id: sellbikedata?.id,
        //   })
        // );
        response.config_owner_type = response?.config_owner_type.map((sellbikedata) => ({
          label: sellbikedata?.value,
          id: sellbikedata?.id,
        }));
        dispatch(setkmData(response?.config_kms_driven));
        // dispatch(setYearsData(response?.config_make_year));
        dispatch(setOwnerTypeData(response?.config_owner_type));

        if (bikeData) {
          // const yearLabel = response?.config_make_year?.find(
          //   (currYear) =>
          //     currYear.id === bikeData?.make_year ||
          //     currYear?.label === String(bikeData?.make_year)
          // );

          // const yearData = {
          //   id: bikeData?.make_year,
          //   label: yearLabel?.label,
          // };
          // dispatch(setSelectedYearsData(yearData));

          const kmLabel = response?.config_kms_driven?.find(
            (currYear) =>
              currYear.id === bikeData?.kms_driven_range ||
              currYear?.label === bikeData?.kms_driven_range,
          );
          const kmData = {
            id: bikeData?.kms_driven,
            label: kmLabel?.label,
          };
          dispatch(setSelectedKmDrivenData(kmData));

          const idOwner = response?.config_owner_type?.find(
            (currYear) => currYear.label === bikeData?.owner_type,
          );
          const ownerData = {
            id: idOwner ? idOwner?.id : null,
            label: bikeData?.owner_type,
          };
          dispatch(setSelectedOwnerType(ownerData));
        }
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getModelData = (id) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi(`model/${id}`)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        response.vehicle_config_model = response?.vehicle_config_model.map((sellbikedata) => ({
          label:
            sellbikedata?.model_name === '-' || sellbikedata?.model_name === ' '
              ? 'NA'
              : sellbikedata?.model_name,
          image: sellbikedata?.model_name,
          id: sellbikedata?.id,
          make_id: sellbikedata?.make_id,
        }));
        if (response?.vehicle_config_model?.length === 1) {
          const modelData = {
            id: response?.vehicle_config_model[0]?.id,
            make_id: response?.vehicle_config_model[0]?.make_id,
            label: response?.vehicle_config_model[0]?.label,
          };
          dispatch(setMoveTab(2));
          dispatch(getVarientData(modelData.id));
          dispatch(setSelectedModelData(modelData));
        }
        dispatch(setModelData(response?.vehicle_config_model));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getVarientData = (id) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi(`variant/${id}`)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        response.vehicle_config_variant = response?.vehicle_config_variant.map((sellbikedata) => ({
          label:
            sellbikedata?.variant_name === '-' || sellbikedata?.variant_name === ' '
              ? 'NA'
              : sellbikedata?.variant_name,
          image: sellbikedata?.image,
          id: sellbikedata?.id,
        }));
        if (response?.vehicle_config_variant?.length === 1) {
          const varSelectData = {
            id: response?.vehicle_config_variant[0]?.id,
            label: response?.vehicle_config_variant[0]?.label,
            image: response?.vehicle_config_variant[0]?.image,
          };
          dispatch(setMoveTab(3));
          dispatch(getYearData(varSelectData.id));
          dispatch(setSelectedVarientData(varSelectData));
        }
        dispatch(setVarientData(response?.vehicle_config_variant));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getYearData = (id) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi(`variant/make_year/${id}`)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        response.config_make_year = response?.map((sellbikedata) => ({
          label: String(sellbikedata?.var_year?.value),
          id: sellbikedata?.var_year?.id,
        }));
        if (response?.config_make_year?.length === 1) {
          const yearSelectData = {
            id: response?.config_make_year[0]?.id,
            label: response?.config_make_year[0]?.label,
          };
          dispatch(setMoveTab(4));
          dispatch(setSelectedYearsData(yearSelectData));
        }
        dispatch(setYearsData(response?.config_make_year));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const createSellBike = (data, history) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .postNodeApi(`sell/create`, data)
      .then((response) => {
        dispatch(setSelectedBikeData(response?.insert_listing_purchase_list_one));
        setTimeout(() => {
          dispatch(setOverlayStatus(false));
          history.push(`/sell-bike/upload-image/${response?.insert_listing_purchase_list_one?.id}`);
        }, 1500);
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const updateBikeData = (data, history) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .postNodeApi('sell/update/step1', data)
      .then((response) => {
        // as per QA request removed the message
        // const data = {
        //   title: 'SUCCESS',
        //   message: 'Sell Bike data has been updated',
        //   status: true,
        //   type: 'success',
        // };
        // dispatch(manageToast(data));
        setTimeout(() => {
          dispatch(setOverlayStatus(false));
          history.push(
            `/sell-bike/upload-image/${response?.update_listing_purchase_list_by_pk?.id}`,
          );
        }, 1500);
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getProvisionalQuote = (data) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .postNodeApi('listing/get-price', data)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        dispatch(setProvisionalPrice(response));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getBikeData = (id) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi(`sell/get/step2/${id}`)
      .then((response) => {
        const bikeData = response?.listing_purchase_list_by_pk;
        dispatch(setSelectedBikeData(bikeData));
        dispatch(setSellBikeDocuments(bikeData?.documents_array));
        // const objPayload = {
        //   cc: parseInt(bikeData?.variant?.cc_value),
        //   kms: parseInt(bikeData?.kms_driven?.split('-')[1]?.replace(',','')),
        //   owner_type: bikeData?.owner_type,
        //   variant_id: bikeData?.variant_id,
        // };
        //dispatch(getProvisionalQuote(objPayload));
        // set sel bike criteria data
        const brandData = {
          id: bikeData?.make?.id,
          brand_logo: bikeData?.make?.brand_logo,
          label: bikeData?.make?.brand_name,
        };
        dispatch(setSelectedBrandsData(brandData));

        const modelData = {
          id: bikeData?.model?.id,
          make_id: bikeData?.make?.id,
          label: bikeData?.model?.model_name,
        };
        dispatch(setSelectedModelData(modelData));

        const variantData = {
          id: bikeData?.variant?.id,
          cc_value: bikeData?.variant?.cc_value,
          label: bikeData?.variant?.variant_name,
          image: bikeData?.variant?.image,
          color: bikeData?.variant?.color,
        };
        const year = {
          id: bikeData?.make_year,
          label: bikeData?.make_year,
        };
        dispatch(setSelectedVarientData(variantData));
        dispatch(setSelectedYearsData(year));
        dispatch(getStaticData(bikeData));
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const submitBikeData = (data, history) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .postNodeApi('sell/update/step2', data)
      .then((response) => {
        if (response?.message) {
          const dataErrorMessage = {
            title: 'ERROR',
            message: response.message,
            status: true,
            type: 'error',
          };
          dispatch(manageToast(dataErrorMessage));
        } else {
          history.push(
            `/sell-bike/upload-document/${response?.update_listing_purchase_list_by_pk?.id}`,
          );
        }
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const addImage = (data, index = null, bikeData = null) => {
  const requestObj = {
    uploaded_files: data,
  };
  return (dispatch, getState) => {
    dispatch(setOverlayStatus(true));
    utils
      .postFormDataForImage('utility/upload-image', requestObj)
      .then((response) => {
        if (response.length) {
          if (bikeData) {
            const imgResponse = {
              title: data?.name,
              src: response[0]?.location,
            };
            bikeData.image_urls[index] = imgResponse;
            dispatch(setSelectedBikeData(bikeData));
          } else {
            const imgResponse = {
              title: data?.name,
              type: index,
              src: response[0]?.location,
            };

            const sellBikeDoc = getState().sellbike.sellBikeDocuments;
            sellBikeDoc.push(imgResponse);
            dispatch(setSellBikeDocuments(sellBikeDoc));
          }
        }

        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const sendDocuments = (data, id, history) => {
  const requestObj = {
    id: id + '',
    documents: data,
  };
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .postNodeApi('sell/update/step3', requestObj)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        history.push('/my-account/sell-order');
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getRecommendedBikeData = () => {
  return (dispatch) => {
    dispatch(setRecommendedBikeData(recommendationBikeData));
  };
};
