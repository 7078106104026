import { clarity } from 'clarity-js';
import { APP_CONFIG } from '../../../appConfig';

const initializeClarity = () => {
  clarity.start({
    projectId: APP_CONFIG.CLARITY_PROJECT_ID,
    track: true,
    upload: 'https://m.clarity.ms/collect',
    content: true,
  });
};

export default initializeClarity;
