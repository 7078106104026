export const SideBar = [
  {
    title: 'Bookings',
    caption: 'See Bookings and RTO status',
    url: '/my-account/bookings',
  },
  {
    title: 'Cart',
    caption: 'View and Edit your cart',
    url: '/my-account/cart',
  },
  {
    title: 'Sell Orders',
    caption: 'View Track and Manage Sell Orders',
    url: '/my-account/sell-order',
  },
  {
    title: 'RTO Services',
    caption: 'See Bookings and RTO status',
    url: '/rto-services',
  },
  {
    title: 'Help & Support',
    caption: 'See bookings and RTO status',
    url: '/help-support',
  },
  {
    title: 'Finance',
    caption: 'See bookings and RTO status',
    url: '/finance',
  },
];

export const MobSideBar = [
  {
    title: 'Wishlist',
    caption: '',
    url: '/wishlist',
  },
  {
    title: 'My profile',
    caption: '',
    url: '/my-account/profile',
  },
];

export const TempDataForUserDetails = {
  first: 'Lorem',
  last: 'Ipsum',
  phone: '+919999999999',
  email: 'lorem.ipsum@gmail.com',
  message: 'Complete your profile',
};

export const showBookingButton = (transCount, myTransCount, sdnCount) => {
  if (
    (transCount > 0 && myTransCount === 1) ||
    (transCount === 0 && myTransCount === 0 && sdnCount === 0) ||
    (sdnCount > 0 && myTransCount === 1)
  ) {
    return true;
  }
  return false;
};

export const showBookTestRideButton = (
  myTransCount,
  transCount,
  myBookingCount,
  sdnCount,
  login = true
) => {
  if (!login && transCount === 0 && sdnCount === 0) {
    return false;
  } else if (
    (transCount > 0 && myBookingCount === 0 && myTransCount === 1) ||
    (transCount === 0 &&
      myBookingCount === 0 &&
      myTransCount === 0 &&
      sdnCount === 0)
  ) {
    return false;
  }
  return true;
};
