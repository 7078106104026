import {
  GET_USER_DETAILS,
  SELECTED_CITY,
  SEND_OTP,
  SET_USER_DETAILS,
  VERIFY_OTP,
} from '../../actions/actionTypes';
import * as utils from '../../axiosUtils/appUtils';
import {
  manageToast,
  setOverlayStatus,
} from '../common/actions';

export const setOtp = (data) => {
  return {
    type: SEND_OTP,
    payload: data,
  };
};

export const setToken = (data) => {
  return {
    type: VERIFY_OTP,
    payload: data,
  };
};

export const GetUserDetails = (data) => {
  return {
    type: GET_USER_DETAILS,
    payload: data,
  };
};

export const SetUserDetails = (data) => {
  return {
    type: SET_USER_DETAILS,
    payload: data,
  };
};

export const SetSelectedCity = (data) => {
  return {
    type: SELECTED_CITY,
    payload: data,
  };
};

export const sendOtp = (data, history, resend = false) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .postNodeApi('auth/request-login-otp', data)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        dispatch(setOtp(response?.id));
        if (!resend) {
          history.push({
            pathname:'/login/verify-otp', 
            state: {mobileNo : data?.phone_number}
          })
        }
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const verifyOtp = (data, history) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .postNodeApi('auth/login-via-otp', data)
      .then((response) => {
        dispatch(setOverlayStatus(false));

        if (response?.message) {
          const data = {
            title: 'Error',
            message: 'Please Enter Valid OTP',
            status: true,
            type: 'error',
          };

          dispatch(manageToast(data));
        } else {
          dispatch(setToken(response));
          localStorage.setItem('accessToken', response?.accessToken);

          const redirectUrl = localStorage.getItem('redirectUrl');
          if (redirectUrl) {
            history.push(redirectUrl);
            localStorage.removeItem('redirectUrl');
          } else {
            history.push(`/`);
          }

          dispatch(getUserInfo());
        }
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getUserInfo = () => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi('general/get-user')
      .then((response) => {
        dispatch(setOverlayStatus(false));
        let respObj = response && response.auth_user && response.auth_user[0];
        dispatch(GetUserDetails({ ...respObj, loginStatus: true }));
      })
      .catch((error) => {
        localStorage.clear();
        window.location.href = '/login';
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const addProfilePicture = (data, history) => {
  const requestObj = {
    uploaded_files: data?.updated_picture,
  };
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .postFormDataForImage('utility/upload-image', requestObj)
      .then((response) => {
        if (response.length) {
          let objData = {
            phone_number: data?.phone_number,
            profile_picture: response[0]?.location,
            first_name: data?.first_name,
            last_name: data?.last_name,
            city: data?.city,
            state: data?.state,
            email: data?.email,
          };
          dispatch(setUserInfo(objData, history));
        }
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const setUserInfo = (data, history) => {
  return (dispatch, getState) => {
    dispatch(setOverlayStatus(true));
    let objResp = {
      user_id: getState()?.auth?.user?.id,
      phone_number: data?.phone_number,
      profile_picture: data?.profile_picture,
      first_name: data?.first_name,
      last_name: data?.last_name,
      city: data?.city,
      state: data?.state,
      whatsapp_updates: data?.whatsapp_updates,
      email: data?.email,
    };
    utils
      .postNodeApi('general/update-user', objResp)
      .then((response) => {
        dispatch(SetUserDetails(response?.update_auth_user?.returning[0]));
        const message = {
          title: 'SUCCESS',
          message: 'User Profile Updated Successfully',
          status: true,
          type: 'success',
        };
        dispatch(manageToast(message));
        dispatch(setOverlayStatus(false));
        history.push('/my-account/bookings');
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getUserLogout = (path) => {
  localStorage.clear();
  window.location.href = path;
};
