import {
  SET_OVERLAY,
  SET_LOGGED_IN_USER,
  MANAGE_DIALOG,
  MANAGE_TOAST,
  SET_COMPONENT_OVERLAY,
  SET_FAQ,
  SET_CITYLIST,
  SET_TOP_BRANDS,
  SET_CONTACT_US_MESSAGE,
  SET_TESTIMONIALS,
  SET_HOW_YOU_GOT_ABOUT_US,
  SET_RTO_SERVICES,
  SELCTION_CITY
} from '../../actions/actionTypes';

const getInitialState = () => ({
  overlayStatus: false,
  componentOverlayStatus: false,
  userDetails: null,
  toast: {
    title: '',
    message: '',
    status: false,
    type: '',
  },
  dialog: {
    title: '',
    message: '',
    status: false,
    type: '',
  },
  faqs: [],
  cities: [],
  selection_cities: [],
  topBrands: [],
  contactUsMsg: false,
  testimonials: [],
  contactHowYouGotAboutUs: [],
  contactRtoServices:[],
});

export default function CommonReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_OVERLAY:
      return Object.assign({}, state, {
        overlayStatus: action.payload,
      });
    case SET_COMPONENT_OVERLAY:
      return Object.assign({}, state, {
        componentOverlayStatus: action.payload,
      });
    case SET_LOGGED_IN_USER:
      return Object.assign({}, state, {
        userDetails: action.payload,
      });
    case MANAGE_TOAST:
      return Object.assign({}, state, {
        toast: action.payload,
      });
    case MANAGE_DIALOG:
      return Object.assign({}, state, {
        dialog: action.payload,
      });
    case SET_FAQ:
      return Object.assign({}, state, {
        faqs: action.payload,
      });
    case SET_CONTACT_US_MESSAGE:
      return Object.assign({}, state, {
        contactUsMsg: action.payload,
      });
    case SET_CITYLIST:
      return Object.assign({}, state, {
        cities: action.payload,
      });
    case SET_TOP_BRANDS:
      return Object.assign({}, state, {
        topBrands: action.payload,
      });
    case SET_TESTIMONIALS :
      return Object.assign({}, state, {
        testimonials: action.payload,
      });
    case SET_HOW_YOU_GOT_ABOUT_US :
      return Object.assign({}, state, {
        contactHowYouGotAboutUs: action.payload,
      });
    case SET_RTO_SERVICES :
      return Object.assign({}, state, {
        contactRtoServices: action.payload,
      });
      case SELCTION_CITY:
      return Object.assign({}, state, {
        selection_cities: action.payload,
      });
    default:
      return state;
  }
}
