import {
  SET_ALL_BIKE_DATA,
  SET_ALL_MARKETING_BANNER_DATA,
  SET_API_FILTER_DATA,
  SET_FILTER_DATA,
  SET_FIND_YOUR_BIKE_IN,
  SET_PRODUCT_SLIDER,
  SET_ALL_WISHLIST_DATA,
  SET_YOU_MAY_ALSO_LIKE,
  SET_BIKE_DETAIL,
  SET_BOOKING_INFO,
  SET_PAYMENT_DATA,
  SET_FORMATTED_FILTER_DATA,
  SET_FILTER_CHIPS,
  VERIFY_TRANSACTION,
  RESET_INITIAL_STATE,
  SET_BIKES_LOADER,
} from '../../actions/actionTypes';
import { initialLimit } from '../../data/staticAppData';

const getInitialState = () => ({
  bikeList: [],
  apiFilterData: {
    condition: {
      _and: [
        {
          _or: [{ variant_vehicle_type: {} }],
        },
        {
          _or: [{ brand_name: {} }],
        },
        { make_year: {} },
        { discounted_price: {} },
        { kms_driven: {} },
        {
          _or: [{ cc_value: {} }],
        },
        {
          _or: [{ fuel_type: {} }],
        },
        {
          _or: [{ color: {} }],
        },
        { _or: [{ store_title: {} }] },
        { city_id: { _eq: 'b3fad187-551e-4b10-ba5f-7e86fb91cd07' } },
      ],
    },
    limit: initialLimit,
    offset: 0,
    order_by: { publish_date: 'desc_nulls_last' },
  },
  bikesLoader: {
    loader: false,
    totalCount: 0,
  },
  filterData: null,
  formattedSelectedFilters: [],
  marketingBannerData: [],
  findYourBikeIn: [],
  productSlider: [],
  youMayAlsoLike: [],
  filterChips: [],
  bikeDetail: null,
  bookingInfo: null,
  bookingPaymentInfo: {
    isLoaded: false,
    data: null,
  },
  verifyTransactions: {
    loading: true,
  },
  bikeId: null,
});

export default function ViewBikeReducer(state = getInitialState(), action) {
  switch (action.type) {
    case RESET_INITIAL_STATE:
      return getInitialState();
    case SET_ALL_BIKE_DATA:
      return Object.assign({}, state, {
        ...state,
        bikeList: action.payload,
      });
    case SET_ALL_WISHLIST_DATA:
      return Object.assign({}, state, {
        ...state,
        wishList: action.payload.map((dt) => dt.purchase_list),
      });
    case SET_ALL_MARKETING_BANNER_DATA:
      return Object.assign({}, state, {
        marketingBannerData: action.payload,
      });
    case SET_API_FILTER_DATA:
      return Object.assign({}, state, {
        apiFilterData: action.payload,
      });
    case SET_FILTER_DATA:
      return Object.assign({}, state, {
        filterData: action.payload,
      });
    case SET_BIKES_LOADER:
      return Object.assign({}, state, {
        bikesLoader: action.payload,
      });
    case SET_FORMATTED_FILTER_DATA:
      return Object.assign({}, state, {
        formattedSelectedFilters: action.payload,
      });
    case SET_FILTER_CHIPS:
      return Object.assign({}, state, {
        filterChips: action.payload,
      });
    case SET_FIND_YOUR_BIKE_IN:
      return Object.assign({}, state, {
        findYourBikeIn: action.payload,
      });
    case SET_PRODUCT_SLIDER:
      return Object.assign({}, state, {
        productSlider: action.payload,
      });
    case SET_YOU_MAY_ALSO_LIKE:
      return Object.assign({}, state, {
        youMayAlsoLike: action.payload,
      });
    case SET_BIKE_DETAIL:
      return Object.assign({}, state, {
        bikeDetail: action.payload,
      });
    case SET_BOOKING_INFO:
      return Object.assign({}, state, {
        bookingInfo: action.payload,
      });
    case SET_PAYMENT_DATA:
      return Object.assign({}, state, {
        bookingPaymentInfo: action.payload,
      });
    case VERIFY_TRANSACTION:
      return Object.assign({}, state, {
        verifyTransactions: action.payload,
      });
    default:
      return state;
  }
}
