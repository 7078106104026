import {
  SET_ALL_BIKE_DATA,
  SET_ALL_MARKETING_BANNER_DATA,
  SET_FILTER_DATA,
  SET_API_FILTER_DATA,
  SET_FIND_YOUR_BIKE_IN,
  SET_PRODUCT_SLIDER,
  SET_ALL_WISHLIST_DATA,
  SET_YOU_MAY_ALSO_LIKE,
  SET_BIKE_DETAIL,
  SET_FORMATTED_FILTER_DATA,
  SET_BOOKING_INFO,
  SET_PAYMENT_DATA,
  SET_FILTER_CHIPS,
  VERIFY_TRANSACTION,
  RESET_INITIAL_STATE,
  SET_BIKES_LOADER,
} from '../../actions/actionTypes';
// import { get, postNodeApi } from '../../axiosUtils/appUtils';
import * as utils from '../../axiosUtils/appUtils';
import { manageToast, setOverlayStatus } from '../common/actions';
// import // GET_BIKE_DETAIL,
// // GET_BIKE_DETAIL_LOGGED_IN,
// '../../data/viewBikeQueries';

export const setAllBikeData = (data) => {
  return {
    type: SET_ALL_BIKE_DATA,
    payload: data,
  };
};

export const setAllMarketingBannerData = (data) => {
  return {
    type: SET_ALL_MARKETING_BANNER_DATA,
    payload: data,
  };
};

export const setApiFilterData = (data) => {
  return {
    type: SET_API_FILTER_DATA,
    payload: data,
  };
};

export const setAllFilterData = (data) => {
  return {
    type: SET_FILTER_DATA,
    payload: data,
  };
};

export const setFilterChips = (data) => {
  return {
    type: SET_FILTER_CHIPS,
    payload: data,
  };
};

export const setFormattedFilterData = (data) => {
  return {
    type: SET_FORMATTED_FILTER_DATA,
    payload: data,
  };
};

export const setFindYourBike = (data) => {
  return {
    type: SET_FIND_YOUR_BIKE_IN,
    payload: data,
  };
};

export const setProductSlider = (data) => {
  return {
    type: SET_PRODUCT_SLIDER,
    payload: data,
  };
};
export const setYouMayAlsoLike = (data) => {
  return {
    type: SET_YOU_MAY_ALSO_LIKE,
    payload: data,
  };
};
export const setBikeDetail = (data) => {
  return {
    type: SET_BIKE_DETAIL,
    payload: data,
  };
};
export const setBookingInfo = (data) => {
  return {
    type: SET_BOOKING_INFO,
    payload: data,
  };
};

export const getFindYourBike = (data) => {
  return (dispatch, getState) => {
    //dispatch(setOverlayStatus(true));
    const selectedCityStore = getState()?.auth?.selected_city;
    utils
      .getNodeApi(`store/get-store-list/${selectedCityStore}`)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        dispatch(setFindYourBike(response?.listing_store));
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log(error);
      });
  };
};

export const resetBikeFilterData = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};

export const getBikeDetailById = (data) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    dispatch(setBikeDetail(null));
    utils
      .getNodeApi(`general/detail/${data.listing_id}`, {})
      .then((response) => {
        dispatch(setOverlayStatus(false));
        dispatch(setBikeDetail(response?.listing_public_purchase[0]));
        // dispatch(getBikeExtraImg(data));
        dispatch(getTestRideAmount());
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log(error);
      });
  };
};

export const getTestRideAmount = () => {
  return (dispatch, getState) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi('purchase-booking/test-ride-amount', {})
      .then((response) => {
        dispatch(setOverlayStatus(false));
        const bikeDetail = {
          ...getState()?.buyBikes?.bikeDetail,
          testAmount: response?.value,
        };
        dispatch(setBikeDetail(bikeDetail));
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log(error);
      });
  };
};

// export const getBikeExtraImg = (data) => {
//   return (dispatch, getState) => {
//     dispatch(setOverlayStatus(true));
//     utils
//       .getNodeApi(`purchase-list/${data.listing_id}`, {})
//       .then((response) => {
//         dispatch(setOverlayStatus(false));
//         let dataSet = {...getState()?.buyBikes?.bikeDetail, images:[...getState()?.buyBikes?.bikeDetail?.images, ...response?.admin_images]}
//         dispatch(setBikeDetail(dataSet));
//         dispatch(getTestRideAmount());
//       })
//       .catch((error) => {
//         dispatch(setOverlayStatus(false));
//         console.log(error);
//       });
//   };
// };

export const getYouMayAlsoLikeAllApi = () => {
  return (dispatch, getState) => {
    dispatch(setOverlayStatus(true));
    dispatch(setYouMayAlsoLike([]));
    const selectedCityid = getState()?.auth?.selected_city;
    utils
      .getNodeApi(`general/you-may-also-like-all/${selectedCityid}`)
      .then(async (response) => {
        dispatch(setOverlayStatus(false));
        const setAllData = [
          {
            id: 1,
            label: 'All',
            selected: true,
            bikesData: response?.listing_public_purchase,
            // response?.listing_public_purchase.length <= 3
            //   ? [
            //     ...response?.listing_public_purchase,
            //     ...response?.listing_public_purchase,
            //   ]
            //   : response?.listing_public_purchase,
          },
        ];
        const scotterData = await getYouMayAlsoLikeTypeWiseApi('SCOOTER', selectedCityid);
        const item2 = {
          id: 2,
          label: 'Scooters',
          selected: false,
          bikesData: scotterData.status === 1 ? scotterData.item : [],
        };
        setAllData.push(item2);
        const moterBikeData = await getYouMayAlsoLikeTypeWiseApi('MOTOR_BIKE', selectedCityid);
        const item3 = {
          id: 3,
          label: 'Motorbikes',
          selected: false,
          bikesData: moterBikeData.status === 1 ? moterBikeData.item : [],
        };
        setAllData.push(item3);
        const electricData = await getYouMayAlsoLikeTypeWiseApi('ELECTRIC', selectedCityid);
        const item4 = {
          id: 4,
          label: 'Electrics',
          selected: false,
          bikesData: electricData.status === 1 ? electricData.item : [],
        };
        setAllData.push(item4);
        dispatch(setYouMayAlsoLike(setAllData));
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log(error);
      });
  };
};
export const getYouMayAlsoLikeTypeWiseApi = (type = 'SCOOTER', selectedCityid) => {
  return utils
    .getNodeApi(`general/you-may-also-like-all-by-type/${type}/${selectedCityid}`)
    .then((response) => {
      const item = response?.listing_public_purchase;
      // response?.listing_public_purchase.length <= 3
      //   ? [
      //       ...response?.listing_public_purchase,
      //       ...response?.listing_public_purchase,
      //     ]
      //   : response?.listing_public_purchase;
      /* if (type === 'MOTOR_BIKE') {
        item.id = 3;
        item.label = 'Motorbikes';
      } else if (type === 'ELECTRIC') {
        item.id = 4;
        item.label = 'Electrics';
      } */
      return { status: 1, item };
    })
    .catch((error) => {
      console.log(error);
      return { status: 0 };
    });
};

export const setAllWishList = (data) => {
  return {
    type: SET_ALL_WISHLIST_DATA,
    payload: data,
  };
};

export const setVarification = (data) => {
  return {
    type: VERIFY_TRANSACTION,
    payload: data,
  };
};

export const setBikeLoader = (data) => {
  return {
    type: SET_BIKES_LOADER,
    payload: data,
  };
};

export const addToWishList = (data, filters, page = 'listing') => {
  return (dispatch) => {
    //dispatch(setOverlayStatus(true));
    utils
      .postNodeApi('wishlist/add', data)
      .then((response) => {
        dispatch(getAllWishlist());
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const removeFromWishList = (data, filters, page = 'listing') => {
  return (dispatch) => {
    //dispatch(setOverlayStatus(true));
    utils
      .postNodeApi('wishlist/remove', data)
      .then((response) => {
        dispatch(getAllWishlist());
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

const handlePayloadCases = (val, selectedCity) => {
  let data = [];
  data = val.filter((item) => {
    if (item?._or?.length > 0 && Object.keys(Object.values(item?._or[0])[0]).length > 0) {
      return item;
    } else if (
      Object.keys(item)[0] !== 'city_id' &&
      Object.keys(item)[0] !== '_or' &&
      Object.keys(Object.values(item)[0]).length > 0
    ) {
      return item;
    }
    return null;
  });
  if (data.length > 0) {
    return [...data, { city_id: { _eq: selectedCity } }];
  }
  return [{ city_id: { _eq: selectedCity } }];
};

const handleSearchPayloadCases = (val, selectedCity, search) => {
  let data = [];
  data = val.filter((item) => {
    if (item?._or?.length > 0 && Object.keys(Object.values(item?._or[0])[0]).length > 0) {
      return item;
    } else if (
      Object.keys(item)[0] !== 'city_id' &&
      Object.keys(item)[0] !== '_or' &&
      Object.keys(Object.values(item)[0]).length > 0
    ) {
      return item;
    }
    return null;
  });
  if (data.length > 0) {
    return [
      ...data,
      { city_id: { _eq: selectedCity } },
      {
        _or: [
          {
            model_name: {
              _ilike: `%${search}%`,
            },
          },
          {
            brand_name: {
              _ilike: `%${search}%`,
            },
          },
        ],
      },
    ];
  }
  return [
    { city_id: { _eq: selectedCity } },
    {
      _or: [
        {
          model_name: {
            _ilike: `%${search}%`,
          },
        },
        {
          brand_name: {
            _ilike: `%${search}%`,
          },
        },
      ],
    },
  ];
};

export const getListing = (data, search, offset = 0) => {
  return (dispatch, getState) => {
    //dispatch(setOverlayStatus(true));
    dispatch(setBikeLoader({ loader: true, totalCount: 0 }));
    const selectedCity = getState()?.auth?.selected_city;
    const existingBikes = getState()?.buyBikes?.bikeList;
    let objPayload = {
      condition: {
        _and: search
          ? handleSearchPayloadCases(data?.condition?._and, selectedCity, search)
          : handlePayloadCases(data?.condition?._and, selectedCity),
      },
      limit: data?.limit,
      offset: offset,
      order_by: data?.order_by,
    };
    utils
      .postNodeApi('listing/buy/read', objPayload)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        setTimeout(() => {
          dispatch(
            setBikeLoader({
              loader: false,
              totalCount: response?.total?.aggregate?.count,
            }),
          );
          dispatch(setAllBikeData([...existingBikes, ...response?.listing_public_purchase]));
        }, 800);
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getSideBarDetails = (data) => {
  return (dispatch, getState) => {
    dispatch(setOverlayStatus(true));
    dispatch(setAllFilterData(null));
    utils
      .getNodeApi(`sidebar/${data?.city_id}`)
      .then((response) => {
        const budgetRange = response?.range?.find((currData) => currData.name === 'budget');
        const yearRange = response?.range?.find((currData) => currData.name === 'year');
        const kmRange = response?.range?.find((currData) => currData.name === 'kilometers');

        response.budgetRange = budgetRange;
        response.yearRange = yearRange;
        response.kmRange = kmRange;

        response.other_brands = response?.other_brands.map((currBrand) => ({
          label: currBrand?.brand_name,
          bike_count: null,
          isSelected: null,
        }));
        response.top_brands = response?.top_brands.map((currBrand) => ({
          label: currBrand?.brand_name,
          bike_count: null,
          isSelected: null,
        }));
        response.listing_store = response?.listing_store?.map((currStore) => ({
          label: currStore?.store_title,
          bike_count: currStore?.purchase_lists_aggregate?.aggregate?.count,
          isSelected: null,
        }));

        response.listing_fuel = [
          {
            label: 'PETROL',
            bike_count: response?.fuel_type_petrol?.aggregate?.count,
            isSelected: null,
          },
          {
            label: 'ELECTRIC',
            bike_count: response?.fuel_type_electric?.aggregate?.count,
            isSelected: null,
          },
          {
            label: 'HYBRID',
            bike_count: response?.fuel_type_hybrid?.aggregate?.count,
            isSelected: null,
          },
        ];

        response.listing_engine = [
          {
            label: 'Below 100 CC',
            bike_count: response?.engine_trim_b100?.aggregate?.count,
            id: 100,
            isSelected: null,
          },
          {
            label: 'Below 200 CC',
            bike_count: response?.engine_trim_b200?.aggregate?.count,
            id: 200,
            isSelected: null,
          },
          {
            label: 'Below 300 CC',
            bike_count: response?.engine_trim_b300?.aggregate?.count,
            id: 300,
            isSelected: null,
          },
          {
            label: 'Below 400 CC',
            bike_count: response?.engine_trim_b400?.aggregate?.count,
            id: 400,
            isSelected: null,
          },
          {
            label: 'Below 500 CC',
            bike_count: response?.engine_trim_b500?.aggregate?.count,
            id: 500,
            isSelected: null,
          },
          {
            label: 'Above 500 CC',
            bike_count: response?.engine_trim_a500?.aggregate?.count,
            id: 'a500',
            isSelected: null,
          },
        ];

        const filters = getState()?.buyBikes?.apiFilterData;
        dispatch(setAllFilterData(response));
        //dispatch(getListing(filters));
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(manageToast(error));
        dispatch(setOverlayStatus(false));
      });
  };
};

export const getAllWishlist = (sort = 'desc') => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .getNodeApi(`wishlist?order=${sort}`)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        dispatch(setAllWishList(response?.listing_wishlist));
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log(error);
      });
  };
};

export const getTransactionVerification = (id, history, location) => {
  return (dispatch, getState) => {
    dispatch(setVarification({ loading: true }));
    dispatch(setOverlayStatus(true));
    const bookingPaymentInfo = getState()?.buyBikes?.bookingPaymentInfo;
    utils
      .getNodeApi(`transactions/verify/${id}`)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        if (response?.id) {
          dispatch(
            setPaymentData({
              ...bookingPaymentInfo,
              transaction_id: response?.id,
            }),
          );
          if (location?.state?.breadCrumb) {
            history.push({
              pathname: `/book-test-ride/${id}`,
              state: {
                breadCrumb: location?.state?.breadCrumb,
                url: location?.state?.url,
              },
            });
          } else {
            history.push(`/book-test-ride/${id}`);
          }
        } else {
          dispatch(
            setVarification({
              loading: false,
              id: response?.id,
              amount: response?.amount,
            }),
          );
          //history.push(`/book-test-ride/payment/${id}`);
        }
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log(error);
      });
  };
};

export const getBookingsVerification = (id) => {
  return (dispatch, getState) => {
    dispatch(setVarification({ loading: true }));
    dispatch(setOverlayStatus(true));
    const bookingPaymentInfo = getState()?.buyBikes?.bookingPaymentInfo;
    utils
      .getNodeApi(`bookings/verify/${id}`)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        if (response?.id) {
          dispatch(
            setPaymentData({
              ...bookingPaymentInfo,
              transaction_id: response?.id,
            }),
          );
          dispatch(
            setVarification({
              loading: false,
              id: response?.id,
              amount: response?.amount,
            }),
          );
        } else {
          dispatch(
            setVarification({
              loading: false,
              id: null,
              amount: response?.amount,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log(error);
      });
  };
};

export const getCreateBookings = (data) => {
  return (dispatch, getState) => {
    dispatch(setOverlayStatus(true));
    const bookingPaymentInfo = getState()?.buyBikes?.bookingPaymentInfo;
    utils
      .postNodeApi('bookings/create', data)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        localStorage.removeItem('TransId');
        dispatch(
          setPaymentData({
            ...bookingPaymentInfo,
            date: response?.booking_date,
            startTime: response?.start_time,
            endTime: response?.end_time,
          }),
        );
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log(error);
      });
  };
};

export const getWhatsappDetails = (data) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .postNodeApi('other/bike-details', data)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        const dataSuccess = {
          title: 'SUCCESS',
          message: 'Bike details are shared on your Whatsapp !',
          status: true,
          type: 'success',
        };
        dispatch(manageToast(dataSuccess));
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log('err', error);
      });
  };
};

export const addLead = (data) => {
  return (dispatch) => {
    dispatch(setOverlayStatus(true));
    utils
      .postNodeApi('general/add-lead', data)
      .then((response) => {
        dispatch(setOverlayStatus(false));
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log(error);
      });
  };
};

export const setPaymentData = (data) => {
  return {
    type: SET_PAYMENT_DATA,
    payload: data,
  };
};

export const getPaymentStatus = (data) => {
  return (dispatch, getState) => {
    dispatch(setOverlayStatus(true));
    const bookingPaymentInfo = getState()?.buyBikes?.bookingPaymentInfo?.data;
    console.log('DTAT', localStorage.getItem('TransId'), bookingPaymentInfo);
    const payload = {
      payment_meta: bookingPaymentInfo,
      transaction_id: localStorage.getItem('TransId'),
    };
    const { bookingPage, history, testRidePaymentStatus, location } = data;
    utils
      .postNodeApi('transactions/payment', payload)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        if (response?.payment_status === 'SUCCESS') {
          dispatch(
            setPaymentData({
              ...bookingPaymentInfo,
              transaction_id: response?.transaction_id,
            }),
          );
          if (testRidePaymentStatus) {
            if (location?.state?.breadCrumb) {
              history.push({
                pathname: `/book-test-ride/${bookingPage}`,
                state: {
                  breadCrumb: location?.state?.breadCrumb,
                  url: location?.state?.url,
                },
              });
            } else {
              history.push(`/book-test-ride/${bookingPage}`);
            }
          }
        } else {
          history.push(`/my-account/cart`);
        }
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log(error);
      });
  };
};

export const getBuyBikePaymentStatus = (data) => {
  return (dispatch, getState) => {
    dispatch(setOverlayStatus(true));
    const payload = {
      listing_id: data?.listing_id,
      booking_id: data?.booking_id || null,
      transaction_id: localStorage.getItem('TransId'),
    };
    const { history } = data;
    utils
      .postNodeApi('purchase-booking/create', payload)
      .then((response) => {
        dispatch(setOverlayStatus(false));
        localStorage.removeItem('TransId');
        history.push(`/buy-bike/success`);
        //history.push(`/my-account/bookings`);
      })
      .catch((error) => {
        dispatch(setOverlayStatus(false));
        console.log(error);
      });
  };
};
