import {
  SEND_OTP,
  VERIFY_OTP,
  GET_USER_DETAILS,
  SET_USER_DETAILS,
  SELECTED_CITY,
} from '../../actions/actionTypes';

const getInitialState = () => ({
  otp: '',
  user: {},
  userDetails: {
    loginStatus: false,
  },
  selected_city: 'b3fad187-551e-4b10-ba5f-7e86fb91cd07',
});

export default function AuthReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SEND_OTP:
      return Object.assign({}, state, {
        ...state,
        otp: action.payload,
      });
    case VERIFY_OTP:
      return Object.assign({}, state, {
        ...state,
        user: action.payload,
      });
    case GET_USER_DETAILS:
      return Object.assign({}, state, {
        ...state,
        userDetails: action.payload,
      });
    case SET_USER_DETAILS:
      return Object.assign({}, state, {
        ...state,
        userDetails: action.payload,
      });
    case SELECTED_CITY:
      return Object.assign({}, state, {
        ...state,
        selected_city: action.payload,
      });
    default:
      return state;
  }
}
