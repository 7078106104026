import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import styles from './styles';
import UserData from './userData';
import HeaderPageLinks from './headerPageLinks';

export const SwipeableHeaderMenu = ({ classes, open, toggleDrawer, loginUser }) => {
  return (
    <SwipeableDrawer
      className={classes.headerSwiper}
      anchor="left"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box pt={16} px={6} className={classes.drawerHeaderWrapper}>
        <UserData loginUser={loginUser} handleCloseModal={toggleDrawer(false)} />
        <HeaderPageLinks loginUser={loginUser} handleCloseModal={toggleDrawer(false)} />
      </Box>
    </SwipeableDrawer>
  );
};

export default withStyles(styles)(SwipeableHeaderMenu);
