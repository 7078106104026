import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../../../src/js/reducers/index';
import storage from 'redux-persist/lib/storage';
export default function configureStore() {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const initialState = {};
  const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  );
  const persistor = persistStore(store);
  return {
    store,
    persistor,
  };
}
