import {
  fileSizeLimit,
  apiToUiFilterMapping,
  engineCCTRimMapping,
  validFileExtn,
} from '../../data/staticAppData';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import {
  getListing,
  setApiFilterData,
  setFilterChips,
  setFormattedFilterData,
} from '../../store/viewBike/actions';
import { APP_CONFIG } from '../../../appConfig';

export const transformNullToString = (value) => {
  if (typeof value === 'number') return value.toString();
  if (!value || value === '.') return '';
  return value.toString();
};

export const amountToThousandSeparator = (value) => {
  return transformNullToString(value)
    .replace(/\D/g, '')
    .replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, '$1,');
};

export const numberToThousandSeparator = (value) => {
  return transformNullToString(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const fileSizeValidation = (selectedFileSize, fileCategory) => {
  if (selectedFileSize > fileSizeLimit[fileCategory]) {
    return true;
  }
  return false;
};

export const fileTypeValidation = (extensions, fileCategory) => {
  let isFileValid = false;
  for (const extn of extensions) {
    if (
      validFileExtn[fileCategory].includes(
        extn.toLowerCase().substring(extn.lastIndexOf('.') + 1)
      )
    ) {
      isFileValid = true;
    } else {
      isFileValid = false;
      break;
    }
  }

  return isFileValid;
};

export const setUiToApiFilterMappingData = (
  selectedFilters,
  formattedFilters
) => {
  const salesFilter = formattedFilters.find(
    (filter) => filter?.type === apiToUiFilterMapping.SALES
  );
  const yearFilter = formattedFilters.find(
    (filter) => filter?.type === apiToUiFilterMapping.YEAR
  );
  const kmsFilter = formattedFilters.find(
    (filter) => filter?.type === apiToUiFilterMapping.KMS
  );
  const variantFilter = formattedFilters.find(
    (filter) => filter?.type === apiToUiFilterMapping.VARIANT
  );
  const colorFilter = formattedFilters.find(
    (filter) => filter?.type === apiToUiFilterMapping.COLOR
  );
  const brandFilter = formattedFilters.find(
    (filter) => filter?.type === apiToUiFilterMapping.BRAND
  );
  const fuelFilter = formattedFilters.find(
    (filter) => filter?.type === apiToUiFilterMapping.FUEL
  );
  const locationFilter = formattedFilters.find(
    (filter) => filter?.type === apiToUiFilterMapping.LOCATION
  );
  const cctrimFilter = formattedFilters.find(
    (filter) => filter?.type === apiToUiFilterMapping.CCTRIM
  );

  if (salesFilter) {
    selectedFilters.condition._and[3].discounted_price = {
      _gte: +salesFilter?.value?.split(' - ')[0],
      _lte: +salesFilter?.value?.split(' - ')[1],
    };
  } else {
    selectedFilters.condition._and[3].discounted_price = {};
  }

  if (yearFilter) {
    selectedFilters.condition._and[2].make_year = {
      _gte: +yearFilter?.value?.split(' - ')[0],
      _lte: +yearFilter?.value?.split(' - ')[1],
    };
  } else {
    selectedFilters.condition._and[2].make_year = {};
  }

  if (kmsFilter) {
    selectedFilters.condition._and[4].kms_driven = {
      _gte: +kmsFilter?.value?.split(' - ')[0],
      _lte: +kmsFilter?.value?.split(' - ')[1],
    };
  } else {
    selectedFilters.condition._and[4].kms_driven = {};
  }

  if (variantFilter) {
    const variantArr = [];
    variantFilter?.value?.forEach((variant) => {
      const variantObj = {
        variant_vehicle_type: { _eq: variant?.value },
      };

      variantArr.push(variantObj);
    });
    selectedFilters.condition._and[0]._or = variantArr;
  } else {
    selectedFilters.condition._and[0]._or = [{ variant_vehicle_type: {} }];
  }

  if (colorFilter) {
    const colorArr = [];
    colorFilter?.value?.forEach((color) => {
      const colorObj = {
        color: { _eq: color?.value },
      };

      colorArr.push(colorObj);
    });
    selectedFilters.condition._and[7]._or = colorArr;
  } else {
    selectedFilters.condition._and[7]._or = [{ color: {} }];
  }

  if (brandFilter) {
    const brandArr = [];
    brandFilter?.value?.forEach((brand) => {
      const brandObj = {
        brand_name: { _eq: brand?.value },
      };

      brandArr.push(brandObj);
    });
    selectedFilters.condition._and[1]._or = brandArr;
  } else {
    selectedFilters.condition._and[1]._or = [{ brand_name: {} }];
  }

  if (fuelFilter) {
    const fuelArr = [];
    fuelFilter?.value?.forEach((fuel) => {
      const fuelObj = {
        fuel_type: { _eq: fuel?.value },
      };

      fuelArr.push(fuelObj);
    });
    selectedFilters.condition._and[6]._or = fuelArr;
  } else {
    selectedFilters.condition._and[6]._or = [{ fuel_type: {} }];
  }

  if (locationFilter) {
    const locationArr = [];
    locationFilter?.value?.forEach((location) => {
      const locationObj = {
        store_title: { _ilike: location?.value },
      };

      locationArr.push(locationObj);
    });
    selectedFilters.condition._and[8]._or = locationArr;
  } else {
    selectedFilters.condition._and[8]._or = [{ store_title: {} }];
  }

  if (cctrimFilter) {
    const ccTrimArr = [];
    cctrimFilter?.value?.forEach((ccTrim) => {
      const ccTrimObj = {
        cc_value: {
          _gte: engineCCTRimMapping[ccTrim?.value] === 'a500' ? 500 : 0,
          _lte:
            engineCCTRimMapping[ccTrim?.value] === 'a500'
              ? 2000
              : engineCCTRimMapping[ccTrim?.value],
        },
      };
      ccTrimArr.push(ccTrimObj);
    });
    selectedFilters.condition._and[5]._or = ccTrimArr;
  } else {
    selectedFilters.condition._and[5]._or = [{ cc_value: {} }];
  }

  return selectedFilters;
};

export const getAptDate = (passedDate) => {
  return moment(new Date(passedDate)).format('Do MMMM, YYYY');
};

export const getAptTime = (passedDate, passedDate2, label = '-') => {
  const start = moment(new Date(passedDate)).format('hh:mm A');
  const end = moment(new Date(passedDate2)).format('hh:mm A');
  return `${start} ${label} ${end}`;
};

export const getFlatFilters = (filters) => {
  let result = [];
  filters.forEach((a) => {
    result.push(a);
    if (Array.isArray(a.value)) {
      result = result.concat(getFlatFilters(a.value));
    }
  });

  return result.filter((currResult) => !Array.isArray(currResult?.value));
};

export const getFlatFiltersForUrl = (array) => {
  // for transforming array like values into comma separated object values to support atob and btoa
  array.forEach((alias, idx) => {
    if (Array.isArray(alias.value)) {
      let tempArr = [...alias.value];
      tempArr.forEach((nestedalias) => {
        if (Array.isArray(array[idx].value)) {
          array[idx].value = nestedalias.value;
        } else {
          array[idx].value = array[idx].value + ',' + nestedalias.value;
        }
      });
    }
  });

  return array;
};

export const convertDecodedFilterToFormatted = (filterObj) => {
  // for transforming comma separated object values into array like values to support atob and btoa
  let formattedArr = [];
  Object.keys(filterObj).forEach((alias) => {
    if (alias === 'Year' || alias === 'Discounted Price' || alias === 'Kms') {
      const singleValueObj = {
        type: alias,
        value: filterObj[alias],
      };

      formattedArr.push(singleValueObj);
    } else {
      filterObj[alias].split(',').forEach((multiFilter) => {
        const multiFilterObj = formattedArr.find((arr) => arr.type === alias);
        if (multiFilterObj) {
          const nestedObj = {
            type: alias,
            value: multiFilter,
          };

          multiFilterObj.value.push(nestedObj);
        } else {
          const multiValueObj = {
            type: alias,
            value: [],
          };

          const nestedObj = {
            type: alias,
            value: multiFilter,
          };

          multiValueObj.value.push(nestedObj);
          formattedArr.push(multiValueObj);
        }
      });
    }
  });

  return formattedArr;
};
export const applySortBy = (
  idx,
  history,
  filter,
  search
) => { 
  history.push({pathname:'/view-bike', search:search ? `?sort=${window.btoa(idx)}&filter=${filter}&search=${search}` : `?sort=${window.btoa(idx)}&filter=${filter}`})
};

export const applyFilterMain = (
  selectedFilters,
  apiFilterData,
  history,
  dispatch
) => {
  dispatch(setFormattedFilterData([...selectedFilters]));

  applyFilterWithFormattedArr(selectedFilters, apiFilterData, dispatch);
  const flatFilter = getFlatFiltersForUrl(cloneDeep(selectedFilters));
  const mappedObj = flatFilter.map((item) => ({ [item.type]: item.value }));
  const flatFilterObj = Object.assign({}, ...mappedObj);

  history.push(
    {pathname:'/view-bike', search:`?filter=${window.btoa(JSON.stringify(flatFilterObj))}`}
  );
};

const applyFilterWithFormattedArr = (arr, apiFilterData, dispatch) => {
  const updatedSelectedFilters = setUiToApiFilterMappingData(
    apiFilterData,
    arr
  );
  dispatch(setApiFilterData(updatedSelectedFilters));
  dispatch(setFilterChips(getFlatFilters(arr)));
  dispatch(getListing(updatedSelectedFilters));
};

export const replacePrefixInUrl = (url) => {
  const oldPrefix = '2wheelr.s3.ap-south-1.amazonaws.com'
  const newPrefix = 'd2yjrpoh2whv2x.cloudfront.net'
  if (url){
    if (url.includes(oldPrefix)) {
      return url.replace(oldPrefix, newPrefix);
    }
  }
  return url;
}

export const updatePrefixInUrl = (url) => {
  const oldPrefix = APP_CONFIG.S3_BUCKET_URL
  const oldPrefixWithoutRegion = APP_CONFIG.S3_BUCKET_URL_WITHOUT_REGION
  const newPrefix = APP_CONFIG.CLOUDFRONT_URL
  if (url) {
    if (url.startsWith('blob:') || url.startsWith('data:image/png')) {
      return url;
    } else if (url.includes(oldPrefix)) {
      return url.replace(oldPrefix, newPrefix);
    } else if (url.includes(oldPrefixWithoutRegion)) {
      return url.replace(oldPrefixWithoutRegion, newPrefix);
    } else if (!url.includes("http") || !url.includes("https")) {
      return `${newPrefix}/${url}`
    }
  }
  return url;
}