import React from 'react';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { Avatar, Badge, Typography, withStyles } from '@material-ui/core';
import { LogoDetails } from '../../data/staticAppData';
import Box from '@material-ui/core/Box';
import styles from './styles';
import { useSelector } from 'react-redux';
import { getWishList } from '../../store/viewBike/selectors';
import { Link } from 'react-router-dom';

export const AccountLevel = ({ classes, loginDetails }) => {
  const list = useSelector((state) => getWishList(state));

  return (
    <>
      {loginDetails?.phone_number ? (
        <>
          {list?.length > 0 ? (
            <Badge classes={{ badge: classes.wishlistBadge }} variant="dot">
              <Link to="/wishlist">
                <FavoriteBorderIcon className={classes.wishIcon} />
              </Link>
            </Badge>
          ) : (
            <Link to="/wishlist">
              <FavoriteBorderIcon className={classes.wishIcon} />
            </Link>
          )}

          <Link to="/my-account/bookings" className="text-decoration-none">
            {loginDetails?.profile_picture ? (
              <Avatar
                alt={loginDetails?.first_name}
                src={loginDetails?.profile_picture}
                className={classes.userAvatar}
              />
            ) : (
              <Avatar className={classes.userAvatar}>{loginDetails?.first_name?.charAt(0)}</Avatar>
            )}
          </Link>
        </>
      ) : (
        <Link to="/login" className="text-decoration-none me-3">
          <Typography variant="h6" color="primary" className={classes.brBottom}>
            Login
          </Typography>
        </Link>
      )}
      <a
        target="_blank"
        rel="noreferrer"
        href={'tel:' + LogoDetails.contact}
        style={{ textDecoration: 'none' }}
      >
        <Box px={2} className={classes.accountWrapper}>
          <Box display="block" fontSize={12} pl={2} className="app-text-color">
            Call Us / WhatsApp
          </Box>
          <Box display="block" fontWeight="fontWeightBold">
            <a
              className={`${classes.contactLinks} ${'app-text-color leto'}`}
              target="_blank"
              rel="noreferrer"
              href={'tel:' + LogoDetails.contact}
            >
              {LogoDetails.contact}
            </a>
          </Box>
        </Box>
      </a>
    </>
  );
};

export default withStyles(styles)(AccountLevel);
