import React, { useState, useEffect } from 'react';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';
import { Container } from 'react-bootstrap';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { isMobile } from 'react-device-detect';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import Toolbar from '@material-ui/core/Toolbar';
import NativeSelect from '@material-ui/core/NativeSelect';
import SearchIcon from '@material-ui/icons/Search';
// import SearchCityDialog from '../../common/searchCityDialog';
import MenuItemHeader from './menuItem';
import AccountLevel from './accountLevel';
import AppLogo from './appLogo';
import Box from '@material-ui/core/Box';
import {
  getCityList,
  getSelectionCityList,
  getTestimonials,
  getTopBrands,
} from '../../store/common/actions';
import SwipeableDrawer from './swipeableDrawer';
import { getSelectedCity, getUser, getUserDetails } from '../../store/auth/selectors';
import { getAllWishlist, setAllBikeData, setApiFilterData } from '../../store/viewBike/actions';
import SearchBox from './SearchBox';
import { SetSelectedCity, getUserInfo } from '../../store/auth/actions';
import { MenuItem, Select } from '@mui/material';
import { getSelectionCity } from '../../store/common/selectors';
import { resetFilterData } from '../../data/staticAppData';
import { useHistory } from 'react-router-dom';

const ScrollTop = (props) => {
  const { children, window, classes } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };
  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
};

const initialState = {
  anchorEl: null,
  open: false,
  openDrawer: false,
};
const Header = (props) => {
  const { classes } = props;
  const [componentstate, setComponentstate] = useState(initialState);
  const [searchId, setSearchId] = React.useState('');
  const [mobileSearch, setMobileSearch] = React.useState(false);
  const loginUser = useSelector((state) => getUser(state));
  const getLoginUserDetails = useSelector((state) => getUserDetails(state));
  const cities = useSelector((state) => getSelectionCity(state));
  const selectedCity = useSelector((state) => getSelectedCity(state));
  const dispatch = useDispatch();
  const history = useHistory();
  const open = Boolean(componentstate.anchorEl);
  const disableSelect =
    window.location.pathname === '/view-bike' || window.location.pathname === '/';
  // const [toggle, setToggle] = useState('false')

  useEffect(() => {
    dispatch(getCityList());
    dispatch(getSelectionCityList());
    dispatch(getTopBrands());
    dispatch(getTestimonials());
    if (!selectedCity) {
      dispatch(SetSelectedCity('b3fad187-551e-4b10-ba5f-7e86fb91cd07'));
    }
    if (loginUser?.phone_number) {
      if (window.location.pathname !== '/wishlist') {
        dispatch(getAllWishlist());
      }
      dispatch(getUserInfo());
    }
  }, [dispatch, loginUser?.phone_number]);

  const handleClick = (event) => {
    setComponentstate({
      ...componentstate,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = () => {
    setComponentstate({
      ...componentstate,
      anchorEl: null,
    });
  };

  const handleCloseDialog = () => {
    setComponentstate({
      ...componentstate,
      open: false,
    });
  };

  const openSearchCityDialog = () => {
    setComponentstate({
      ...componentstate,
      open: !componentstate.open,
    });
  };

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setComponentstate({
      ...componentstate,
      openDrawer: !componentstate.openDrawer,
    });
  };

  const handleCitySelction = (e) => {
    dispatch(SetSelectedCity(e.target.value));
    if (window.location.pathname === '/view-bike') {
      dispatch(setAllBikeData([]));
      dispatch(setApiFilterData(resetFilterData));
      history.push('/view-bike');
    }
  };
  const handelWishlist = () => {
    if (loginUser?.phone_number) {
      dispatch(getAllWishlist());
    }
  };

  if (mobileSearch) {
    return (
      <SearchBox
        classes={classes}
        setSearchId={setSearchId}
        searchId={searchId}
        mobileSearch={mobileSearch}
        setMobileSearch={setMobileSearch}
      />
    );
  }

  return (
    <Container
      fluid
      className={`${'p-0'} ${classes.headerWrapper} ${isMobile && classes.mobileHeaderWrapper}`}
    >
      {isMobile ? (
        <>
          <AppBar position="static" className={classes.mobileAppbar}>
            <Toolbar>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon onClick={toggleDrawer(true)} />
              </IconButton>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={6}>
                  <AppLogo handelWishlist={handelWishlist} />
                </Grid>
                <Grid item xs={5} sm={3} md={3}>
                  <Select
                    displayEmpty
                    value={selectedCity}
                    fullWidth
                    onClick={openSearchCityDialog}
                    open={componentstate.open}
                    disabled={!disableSelect}
                    className={classes.headerSearchDropdown}
                    onChange={(e) => handleCitySelction(e)}
                  >
                    {cities?.map((currCity, cityIdx) => {
                      return <MenuItem value={currCity?.city?.id}>{currCity?.city?.name}</MenuItem>;
                    })}
                  </Select>
                </Grid>
                <Grid item xs={1}>
                  <svg
                    onClick={() => setMobileSearch(true)}
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-2"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.85156 0C4.881 0 0.851562 4.02944 0.851562 9C0.851562 13.9706 4.881 18 9.85156 18C11.9766 18 13.9296 17.2635 15.4693 16.0319L19.1447 19.7071C19.5353 20.0976 20.1684 20.0976 20.5589 19.7071C20.9495 19.3166 20.9494 18.6834 20.5589 18.2929L16.8835 14.6177C18.1151 13.078 18.8516 11.125 18.8516 9C18.8516 4.02944 14.8221 0 9.85156 0ZM2.85156 9C2.85156 5.13401 5.98557 2 9.85156 2C13.7176 2 16.8516 5.13401 16.8516 9C16.8516 12.866 13.7176 16 9.85156 16C5.98557 16 2.85156 12.866 2.85156 9Z"
                      fill="#464A54"
                    />
                  </svg>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>

          <SwipeableDrawer
            open={componentstate.openDrawer}
            toggleDrawer={toggleDrawer}
            loginUser={getLoginUserDetails}
          />
          {/* <BottomNavigationMobile classes={classes} /> */}
        </>
      ) : (
        <AppBar
          position="static"
          color="default"
          id="back-to-top-anchor"
          className={`${'px-4 font-size-20px'} ${classes.headerWhiteBackground}`}
        >
          <Toolbar className="d-flex aligm-items-center deskop-header">
            <AppLogo handelWishlist={handelWishlist} />

            <Box display="flex" mr={6} className={`${'app-input'} ${classes.headerInputWrapper}`}>
              <Select
                displayEmpty
                value={selectedCity}
                onClick={false && openSearchCityDialog}
                disabled={!disableSelect}
                className={classes.headerSearchDropdown}
                onChange={(e) => handleCitySelction(e)}
              >
                {cities?.map((currCity, cityIdx) => {
                  return <MenuItem value={currCity?.city?.id}>{currCity?.city?.name}</MenuItem>;
                })}
              </Select>
              <SearchBox classes={classes} setSearchId={setSearchId} searchId={searchId} />
            </Box>
            <MenuItemHeader
              open={open}
              handleClick={handleClick}
              anchorEl={componentstate.anchorEl}
              handleClose={handleClose}
              handelWishlist={handelWishlist}
            />
            <AccountLevel loginDetails={getLoginUserDetails} />
          </Toolbar>
        </AppBar>
      )}

      {/* <SearchCityDialog open={componentstate.open} handleClose={handleCloseDialog} /> */}

      {!isMobile && (
        <ScrollTop {...props}>
          <Fab
            color="primary"
            className={classes.scrollToTopBtn}
            size="small"
            aria-label="scroll back to top"
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      )}
    </Container>
  );
};
export default withStyles(styles)(Header);
