import { SET_ALL_SELL_ORDER } from '../../actions/actionTypes';

const getInitialState = () => ({
  allSellOrders: [],
});

export default function UserDashboardReducer(
  state = getInitialState(),
  action
) {
  switch (action.type) {
    case SET_ALL_SELL_ORDER:
      return Object.assign({}, state, {
        allSellOrders: action.payload,
      });

    default:
      return state;
  }
}
