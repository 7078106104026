import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './appRouter';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from '@material-ui/core/styles';
import configureStore from './js/store/store';
import themes from './js/themes';
import initializeGA4 from './js/utils/js/googleAnalytics';
import initializeClarity from './js/utils/js/clarity';
import MetaPixel from './js/utils/meta/metaPixel';

const { store, persistor } = configureStore();
const appTheme = themes();

initializeGA4();
initializeClarity();

ReactDOM.render(
  <Provider store={store}>
    <MetaPixel />
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={appTheme}>
        <AppRouter />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
