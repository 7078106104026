import { SET_SELL_ORDER_LIST } from '../../../actions/actionTypes';

const getInitialState = () => ({
  sellList: {},
});

export default function SellOrderReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_SELL_ORDER_LIST:
      return Object.assign({}, state, {
        sellList: action.payload,
      });
    default:
      return state;
  }
}
