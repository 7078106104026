import { SET_BOOKINGS_LIST } from '../../../actions/actionTypes';

const getInitialState = () => ({
  bookingList: {},
});

export default function BookingsReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_BOOKINGS_LIST:
      return Object.assign({}, state, {
        bookingList: action.payload,
      });
    default:
      return state;
  }
}
