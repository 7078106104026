import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { Container } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';
import { NavLink, useHistory } from 'react-router-dom';
import { footerMenu, socialMedia } from '../../data/menuItems';
import { apiToUiFilterMapping, LogoDetails } from '../../data/staticAppData';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { getTopBrand } from '../../store/common/selectors';
import { getApiFilterData, getFormattedFilters } from '../../store/viewBike/selectors';
import { applyFilterMain } from '../../utils/js/helpers';
import { getUserDetails } from '../../store/auth/selectors';

const Footer = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const topBrands = useSelector((state) => getTopBrand(state));
  let selectedFilters = useSelector((state) => getFormattedFilters(state));
  const apiFilterData = useSelector((state) => getApiFilterData(state));
  const getLoginUserDetails = useSelector((state) => getUserDetails(state));

  const setBrandFilter = (item) => {
    const brandFilter = selectedFilters.find(
      (filter) => filter?.type === apiToUiFilterMapping.BRAND,
    );
    if (brandFilter) {
      const brandObj = {
        type: apiToUiFilterMapping.BRAND,
        value: item?.brand_name,
      };

      brandFilter?.value?.push(brandObj);
    } else {
      const brandFilter = {
        type: apiToUiFilterMapping.BRAND,
        value: [
          {
            type: apiToUiFilterMapping.BRAND,
            value: item?.brand_name,
          },
        ],
      };
      selectedFilters.push(brandFilter);
    }

    applyFilter();
  };

  const applyFilter = () => {
    applyFilterMain(selectedFilters, apiFilterData, history, dispatch);
  };

  const handleRedirection = (val) => {
    if (val.name === 'My Account' && !getLoginUserDetails.loginStatus) {
      return '/login';
    }
    return val.state;
  };

  return (
    <footer className={classes.footerWrapper}>
      <Container fluid className="p-5">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container spacing={isMobile ? 2 : 1} justifyContent="space-between">
              {footerMenu?.map((currData, parentIdx) => {
                return (
                  <Grid
                    item
                    xs={currData.name === 'CONTACT US' ? 12 : 6}
                    sm={currData.name === 'CONTACT US' ? 12 : 6}
                    md={2}
                    lg={2}
                  >
                    <Typography className="font-700 font-size-14px">{currData.name}</Typography>
                    <Box pt={3} fontSize={12}>
                      {currData.name === 'BRANDS' ? (
                        <>
                          {topBrands?.map((item, index) => {
                            return (
                              <Box py={1} onClick={() => setBrandFilter(item)}>
                                <NavLink className={classes.footerLinks} to="/view-bike">
                                  {item?.brand_name}
                                </NavLink>
                              </Box>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {currData?.children?.map((currChild, childIdx) => {
                            return (
                              <Box py={1}>
                                {currChild.state !== 'communication' &&
                                  currChild.state !== 'Customer_Support' && (
                                    <NavLink
                                      className={classes.footerLinks}
                                      to={handleRedirection(currChild)}
                                      onClick={() => window.scrollTo(0, 0)}
                                    >
                                      {currChild.name}
                                    </NavLink>
                                  )}
                                {currChild.state === 'Customer_Support' && (
                                  <a href={currChild?.link} className={classes.footerLinks}>
                                    {currChild?.name}
                                  </a>
                                )}
                                {currChild.state === 'communication' && (
                                  <a
                                    className={` '${currChild.type === 'contact' ? 'leto' : ''}' ${
                                      classes.footerLinks
                                    }`}
                                    target="_blank"
                                    rel="noreferrer"
                                    href={
                                      currChild.type === 'contact'
                                        ? 'tel:' + currChild.name
                                        : currChild.type === 'email'
                                        ? 'mailTo:' + currChild.name
                                        : 'https://www.google.com/maps/search/?api=1&query=' +
                                          currChild.name
                                    }
                                  >
                                    {currChild.name}
                                  </a>
                                )}
                              </Box>
                            );
                          })}
                        </>
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} className="ipad-spacing">
            <a
              href={'/'}
              style={{ textDecoration: 'none', color: 'inherit' }}
              rel="noopener noreferrer"
            >
              <Box pt={isMobile ? 6 : 0} display="flex" justifyContent="center" alignItems="center">
                <img className="img-auto m-width" src={LogoDetails.logo} alt="logo"></img>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                pt={1}
                pb={1}
                fontSize={12}
              >
                {LogoDetails.title}
              </Box>
            </a>
            <hr className={!isMobile ? `${classes.dividerLine}` : ''} />

            <Box pt={1} pb={4} px={{ md: 12 }}>
              <Grid container spacing={20} justifyContent="center">
                {socialMedia?.map((currMedia, mediaIdx) => {
                  return (
                    <Grid item xs={2} className="text-center">
                      <a
                        href={currMedia.state}
                        className={classes.footerLinks}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img className="img-auto" src={currMedia.icon} alt={currMedia.name}></img>
                      </a>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            <a
              href={'/'}
              style={{ textDecoration: 'none', color: 'inherit' }}
              rel="noopener noreferrer"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                pt={4}
                pb={{ xs: 10, sm: 10, md: 0, lg: 0 }}
                fontSize={14}
              >
                &copy;&nbsp;<span className="leto-reg">{new Date().getFullYear()}</span>&nbsp;
                {LogoDetails.copyright}
              </Box>
            </a>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};
export default withStyles(styles)(Footer);
