import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const LoadingOverlay = (props) => {
  const { classes, fallback } = props;
  const { overlay, center } = classes;
  if (fallback) {
    return (
      <div>
        <div className={overlay}>
          <div className={center}>
            <CircularProgress color="secondary" />
          </div>
        </div>
        <div className={classes.fallback}></div>
      </div>
    );
  }
  return (
    <div className={overlay}>
      <div className={center}>
        <CircularProgress color="secondary" />
      </div>
    </div>
  );
};
export default withStyles(styles)(React.memo(LoadingOverlay));
