import { SET_CART_LIST } from '../../../actions/actionTypes';

const getInitialState = () => ({
  cartList: {},
});

export default function BookingsReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_CART_LIST:
      return Object.assign({}, state, {
        cartList: action.payload,
      });
    default:
      return state;
  }
}
