import instagram from '../../assets/images/social/instagram.png';
import facebook from '../../assets/images/social/facebook.png';
import linkedin from '../../assets/images/social/linkedin.png';
import twitter from '../../assets/images/social/twitter.png';

export const footerMenu = [
  {
    state: '',
    name: 'QUICK LINKS',
    type: 'sub',
    icon: '',
    children: [
      // {
      //   state: '/admin-registration',
      //   name: 'Admin Registration',
      //   type: 'link',
      //   icon: '',
      // },
      {
        state: '/view-bike',
        name: 'Buy Bike',
        type: 'link',
        icon: '',
      },
      {
        state: '/sell-bike',
        name: 'Sell Bike',
        type: 'link',
        icon: '',
      },
      // {
      //   state: '/rto-assistance',
      //   name: 'RTO Assistance',
      //   type: 'link',
      //   icon: '',
      // },
      {
        state: '/sell-bike',
        name: 'Bike Value Calculator',
        type: 'link',
        icon: '',
      },
      {
        state: '/my-account/bookings',
        name: 'My Account',
        type: 'link',
        icon: '',
      },
      // {
      //   state: '/blog-site',
      //   name: 'Blog Site',
      //   type: 'link',
      //   icon: '',
      // },
      {
        state: 'Customer_Support',
        name: 'Customer Support',
        type: 'link',
        link: 'https://twowheelr.zohodesk.in/portal',
        icon: '',
      },
    ],
  },
  {
    state: '',
    name: 'KNOW MORE',
    type: 'sub',
    icon: '',
    children: [
      {
        state: '/about-us',
        name: 'About Us',
        type: 'link',
        icon: '',
      },
      // {
      //   state: '/how-we-work',
      //   name: 'How We Work',
      //   type: 'link',
      //   icon: '',
      // },
      {
        state: '/contact-us',
        name: 'Contact Us',
        type: 'link',
        link: 'https://www.2wheelr.com/pages/contact-us',
        icon: '',
      },
      // {
      //   state: 'faq',
      //   name: 'FAQs',
      //   type: 'link',
      //   icon: '',
      // },
    ],
  },
  {
    state: '',
    name: 'BRANDS',
    type: 'sub',
    icon: '',
    children: [],
  },
  {
    state: '',
    name: 'LEGAL',
    type: 'sub',
    icon: '',
    children: [
      {
        state: '/privacy-policy',
        name: 'Privacy Policy',
        type: 'link',
        icon: '',
      },
      {
        state: '/refund-policy',
        name: 'Refund Policy',
        type: 'link',
        icon: '',
      },
      {
        state: '/shipping-policy',
        name: 'Shipping and Delivery',
        type: 'link',
        icon: '',
      },
      {
        state: '/terms-and-conditions',
        name: 'Terms & Conditions',
        type: 'link',
        icon: '',
      },
    ],
  },
  {
    state: '',
    name: 'CONTACT US',
    type: 'sub',
    icon: '',
    children: [
      {
        state: 'communication',
        name: '+91 8483893934',
        type: 'contact',
        icon: '',
      },
      {
        state: 'communication',
        name: 'support@2wheelr.com',
        type: 'email',
        icon: '',
      },
      {
        state: 'communication',
        name: '2WheelR Kharadi, S-no, 20/3, Kharadkar Park, Flyover, near Bharat Petrol Pump, Mundhwa, Pune, Maharashtra 411014',
        type: 'address',
        icon: '',
      },
      {
        state: 'communication',
        name: '2WheelR Rasta Peth, 466, Shop No 3, Shan Classic, Rasta Peth, Near Power House, Pune, Maharashtra 411011',
        type: 'address',
        icon: '',
      },
    ],
  },
];

export const headerMenu = [
  {
    state: '/view-bike',
    name: 'Buy Bike',
    type: 'main',
    icon: '',
  },
  {
    state: '/sell-bike',
    name: 'Sell Bike',
    type: 'main',
    icon: '',
  },
  {
    state: '',
    name: 'More',
    type: 'sub',
    icon: '',
    children: [
      {
        state: '/sell-bike',
        name: 'Bike Value Calculator',
        type: 'link',
        icon: '',
      },
      {
        state: '/about-us',
        name: 'About Us',
        type: 'main',
        icon: '',
      },
      {
        state: '/contact-us',
        name: 'Contact Us',
        type: 'main',
        icon: '',
      },
    ],
  },
];

export const headerMenuMobileMain = [
  {
    state: '/view-bike',
    name: 'Buy Bike',
    type: 'main',
    icon: '',
  },
  {
    state: '/sell-bike',
    name: 'Sell Bike',
    type: 'main',
    icon: '',
  },
];

export const headerMenuMobilePrimary = [
  {
    state: '/contact-us',
    name: 'Contact Us',
    type: 'main',
    icon: '',
  },
  {
    state: '/about-us',
    name: 'About Us',
    type: 'main',
    icon: '',
  },
];

export const headerMenuMobileSecondary = [
  {
    state: '/faqs',
    name: 'FAQs',
    type: 'main',
    icon: '',
  },
  {
    state: '/privacy-policy',
    name: 'Privacy Policy',
    type: 'main',
    icon: '',
  },
  {
    state: '/refund-policy',
    name: 'Refund Policy',
    type: 'main',
    icon: '',
  },
  {
    state: '/shipping-policy',
    name: 'Shipping and Delivery',
    type: 'main',
    icon: '',
  },
  {
    state: '/terms-and-conditions',
    name: 'Terms & Conditions',
    type: 'main',
    icon: '',
  },
];

export const socialMedia = [
  {
    state: 'https://instagram.com/2wheelrofficial?igshid=YmMyMTA2M2Y=',
    name: 'Instagram',
    type: 'social_link',
    icon: instagram,
  },
  {
    state: 'https://www.facebook.com/2wheelrofficial',
    name: 'Facebook',
    type: 'social_link',
    icon: facebook,
  },
  {
    state: 'https://www.linkedin.com/company/2wheelr/',
    name: 'LinkedIn',
    type: 'social_link',
    icon: linkedin,
  },
  {
    state: 'https://twitter.com/2wheelrofficial?t=YQSnWOK-MRieyt17HPF0vA&s=09',
    name: 'Twitter',
    type: 'social_link',
    icon: twitter,
  },
];
