import React from 'react';
import { withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import {
  headerMenuMobileMain,
  headerMenuMobilePrimary,
  headerMenuMobileSecondary,
} from '../../data/menuItems';
import Box from '@material-ui/core/Box';
import styles from './styles';
import { MobSideBar, SideBar } from '../../container/myAccount/myAccountMenu/utils';
import { Link } from 'react-router-dom';
import { getUserLogout } from '../../store/auth/actions';

export const HeaderPageLinks = ({ classes, loginUser, handleCloseModal }) => {
  return (
    <>
      {/*My Profile changes for Side Panel*/}
      {loginUser && loginUser.loginStatus &&
        <Box pb={2}>
          <List
            component="nav"
            className={classes.headerLinkList}
            aria-label="other brands"
          >
            {SideBar?.map((currData, dataIdx) => {
              return (
                <Link to={currData?.url} className='text-decoration-none'>
                  <ListItem key={dataIdx} onClick={handleCloseModal}>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary={currData.title}
                    />
                  </ListItem>
                  <Divider />
                </Link>
              );
            })}
            {MobSideBar?.map((currData, dataIdx) => {
              return (
                <Link to={currData?.url} className='text-decoration-none'>
                  <ListItem key={dataIdx} onClick={handleCloseModal}>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary={currData.title}
                    />
                  </ListItem>
                  <Divider />
                </Link>
              );
            })}
          </List>
          <List
            component="nav"
            className={classes.headerLinkList}
            aria-label="other brands"
          >
            <ListItem onClick={()=>getUserLogout('/')}>
              <ListItemText
                classes={{ primary: classes.listItemTextLogout }}
                primary="Logout"
              />
            </ListItem>
          </List>
        </Box>
      }

      <Box pb={2}>
        <List
          component="nav"
          className={classes.headerLinkList}
          aria-label="other brands"
        >
          {headerMenuMobileMain?.map((currData, dataIdx) => {
            return (
              <Link to={currData?.state} className='text-decoration-none'>
                <ListItem key={dataIdx} onClick={handleCloseModal}>
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary={currData.name}
                  />
                </ListItem>
                <Divider />
              </Link>
            );
          })}
        </List>
      </Box>

      <Box pb={2}>
        <List
          component="nav"
          className={classes.headerLinkList}
          aria-label="other brands"
        >
          {headerMenuMobilePrimary?.map((currData, dataIdx) => {
            return (
              <Link to={currData?.state} className='text-decoration-none'>
                <ListItem key={dataIdx} onClick={handleCloseModal}>
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary={currData.name}
                  />
                </ListItem>
                <Divider />
              </Link>
            );
          })}
        </List>
      </Box>

      <Box>
        <List
          component="nav"
          className={classes.headerLinkList}
          aria-label="other brands"
        >
          {headerMenuMobileSecondary?.map((currData, dataIdx) => {
            return (
              <Link to={currData?.state} className='text-decoration-none'>
                <ListItem key={dataIdx} onClick={handleCloseModal}>
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary={currData.name}
                  />
                </ListItem>
                <Divider />
              </Link>
            );
          })}
        </List>
      </Box>
    </>
  );
};

export default withStyles(styles)(HeaderPageLinks);
