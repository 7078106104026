import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
export default function themes() {
  const theme = createTheme({
    spacing: (factor) => `${0.25 * factor}rem`,
    typography: {
      fontFamily: [
        'Kumbh Sans',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'kumbh',
        'sans-serif',
      ].join(','),
    },
    palette: {
      primary: {
        main: '#15716b',
        light: '#3baf9f',
        dark: '#00322F',
        darker: 'rgba(0, 0, 0, 0.87)',
      },
      secondary: {
        main: '#8A8383',
        contrastText: '#fff',
        light: '#e9e9e9',
        lighter: '#ACACAC',
        dark: '#1a1a1a',
        footerBgColor: '#001615',
      },
      cardBg: {
        main: '#d9d9d9',
      },
      badge: {
        warning: '#FFBF00',
      },
    },
    status: {
      danger: 'red',
    },
  });
  return responsiveFontSizes(theme);
}
