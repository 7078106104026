import {
  SET_SELLBIKE_SEARCH_CRITERIA,
  SET_RECOMMENDATION_BIKE_DATA,
  SET_BRAND_DATA,
  SET_SELECTED_BIKE,
  SET_SELECTED_BRAND,
  SET_SELECTED_VARIENT,
  SET_SELECTED_MODEL,
  SET_MODEL_DATA,
  SET_VARIENT_DATA,
  SET_SELECTED_YEAR,
  SET_SELECTED_KM_DRIVEN,
  SET_SELECTED_OWNER_TYPE,
  SET_OWNER_TYPE_DATA,
  SET_KMDRIVEN_DATA,
  SET_YEAR_DATA,
  SET_SELLBIKE_DOCUMENTS,
  RESET_INITIAL_STATE,
  SET_PROVISIONAL_PRICE,
  SET_MOVE_TAB,
} from '../../actions/actionTypes';

const getInitialState = () => ({
  moveTab: 0,
  sellBikeSearchCriteriaData: [],
  selectedBrand: null,
  selectedModel: null,
  selectedVarient: null,
  selectedYear: null,
  selectedKMS: null,
  selectedOwner: null,
  brands: [],
  model: [],
  varient: [],
  years: [],
  kmDriven: [],
  ownerType: [],
  recommendedBikeData: [],
  bikeData: null,
  sellBikeDocuments: [],
  provisional_price: {},
});

export default function SellBikeReducer(state = getInitialState(), action) {
  switch (action.type) {
    case RESET_INITIAL_STATE:
      return getInitialState();
    case SET_SELLBIKE_SEARCH_CRITERIA:
      return Object.assign({}, state, {
        sellBikeSearchCriteriaData: action.payload,
      });
    case SET_BRAND_DATA:
      return Object.assign({}, state, {
        brands: action.payload,
      });
    case SET_VARIENT_DATA:
      return Object.assign({}, state, {
        varient: action.payload,
      });
    case SET_MODEL_DATA:
      return Object.assign({}, state, {
        model: action.payload,
      });
    case SET_YEAR_DATA:
      return Object.assign({}, state, {
        years: action.payload,
      });
    case SET_KMDRIVEN_DATA:
      return Object.assign({}, state, {
        kmDriven: action.payload,
      });
    case SET_OWNER_TYPE_DATA:
      return Object.assign({}, state, {
        ownerType: action.payload,
      });
    case SET_SELECTED_BRAND:
      return Object.assign({}, state, {
        selectedBrand: action.payload,
      });
    case SET_SELECTED_YEAR:
      return Object.assign({}, state, {
        selectedYear: action.payload,
      });
    case SET_PROVISIONAL_PRICE:
      return Object.assign({}, state, {
        provisional_price: action.payload,
      });
    case SET_SELECTED_KM_DRIVEN:
      return Object.assign({}, state, {
        selectedKMS: action.payload,
      });
    case SET_SELECTED_OWNER_TYPE:
      return Object.assign({}, state, {
        selectedOwner: action.payload,
      });
    case SET_SELECTED_MODEL:
      return Object.assign({}, state, {
        selectedModel: action.payload,
      });
    case SET_SELECTED_VARIENT:
      return Object.assign({}, state, {
        selectedVarient: action.payload,
      });
    case SET_RECOMMENDATION_BIKE_DATA:
      return Object.assign({}, state, {
        recommendedBikeData: action.payload,
      });
    case SET_SELECTED_BIKE:
      return Object.assign({}, state, {
        ...state,
        bikeData: action.payload,
      });
    case SET_SELLBIKE_DOCUMENTS:
      return Object.assign({}, state, {
        sellBikeDocuments: action.payload,
      });
      case SET_MOVE_TAB:
        return Object.assign({}, state, {
          moveTab: action.payload,
        });
    default:
      return state;
  }
}
