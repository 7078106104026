// common
export const SET_OVERLAY = 'SET_OVERLAY';
export const MANAGE_TOAST = 'MANAGE_TOAST';
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const MANAGE_DIALOG = 'MANAGE_DIALOG';
export const SET_COMPONENT_OVERLAY = 'SET_COMPONENT_OVERLAY';
export const SET_CITYLIST = 'SET_CITYLIST';
export const SELCTION_CITY = 'SELCTION_CITY';
export const SELECTED_CITY = 'SELECTED_CITY';
export const SET_TOP_BRANDS = 'SET_TOP_BRANDS';

// FAQ
export const SET_FAQ = 'SET_FAQ';

// CONTACT US
export const SET_CONTACT_US_MESSAGE = 'SET_CONTACT_US_MESSAGE';
export const SET_TESTIMONIALS = 'SET_TESTIMONIALS';
export const SET_PROVISIONAL_PRICE = 'SET_PROVISIONAL_PRICE';

//Sell Bike Flow
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE';
export const SET_SELLBIKE_SEARCH_CRITERIA = 'SET_SELLBIKE_SEARCH_CRITERIA';
export const SET_RECOMMENDATION_BIKE_DATA = 'SET_RECOMMENDATION_BIKE_DATA';

export const SET_BRAND_DATA = 'SET_BRAND_DATA';
export const SET_MODEL_DATA = 'SET_MODEL_DATA';
export const SET_VARIENT_DATA = 'SET_VARIENT_DATA';
export const SET_YEAR_DATA = 'SET_YEAR_DATA';
export const SET_KMDRIVEN_DATA = 'SET_KMDRIVEN_DATA';
export const SET_OWNER_TYPE_DATA = 'SET_OWNER_TYPE_DATA';

export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
export const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
export const SET_SELECTED_VARIENT = 'SET_SELECTED_VARIENT';
export const SET_SELECTED_YEAR = 'SET_SELECTED_YEAR';
export const SET_SELECTED_KM_DRIVEN = 'SET_SELECTED_KM_DRIVEN';
export const SET_SELECTED_OWNER_TYPE = 'SET_SELECTED_OWNER_TYPE';

export const SET_SELLBIKE_DOCUMENTS = 'SET_SELLBIKE_DOCUMENTS';
export const SET_MOVE_TAB = 'SET_MOVE_TAB';
// MyAccount
//Sell Order Page Listing
export const SET_SELL_ORDER_LIST = 'SET_SELL_ORDER_LIST';
//Bookings Page Listing
export const SET_BOOKINGS_LIST = 'SET_BOOKINGS_LIST';
//Cart Page Listing
export const SET_CART_LIST = 'SET_CART_LIST';

export const SET_HOW_YOU_GOT_ABOUT_US = 'SET_HOW_YOU_GOT_ABOUT_US';
export const SET_RTO_SERVICES = 'SET_RTO_SERVICES';
// Bike Listing Page
export const SET_ALL_BIKE_DATA = 'SET_ALL_BIKE_DATA';
export const SET_ALL_MARKETING_BANNER_DATA = 'SET_ALL_MARKETING_BANNER_DATA';
export const SET_API_FILTER_DATA = 'SET_API_FILTER_DATA';
export const SET_FORMATTED_FILTER_DATA = 'SET_FORMATTED_FILTER_DATA';
export const SET_FILTER_CHIPS = 'SET_FILTER_CHIPS';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SET_ALL_WISHLIST_DATA = 'SET_ALL_WISHLIST_DATA';
export const SET_BIKES_LOADER = 'SET_BIKES_LOADER';

// Bike Detail Page
export const SET_SELECTED_BIKE = 'SET_SELECTED_BIKE';

// Dashboard Pages
export const SET_ALL_SELL_ORDER = 'SET_ALL_SELL_ORDER';

// home page action type
export const SET_FIND_YOUR_BIKE_IN = 'SET_FIND_YOUR_BIKE_IN';
export const SET_PRODUCT_SLIDER = 'SET_PRODUCT_SLIDER';

//view bike
export const SET_YOU_MAY_ALSO_LIKE = 'SET_YOU_MAY_ALSO_LIKE';
export const SET_BIKE_DETAIL = 'SET_BIKE_DETAIL';
export const SET_BOOKING_INFO = 'SET_BOOKING_INFO';
export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';
export const VERIFY_TRANSACTION = 'VERIFY_TRANSACTION';

// AUTH

export const SEND_OTP = 'SEND_OTP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
