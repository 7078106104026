import { createSelector } from 'reselect';

const authReducerSelector = (state) => state.auth;

export const getAuth = createSelector([authReducerSelector], (auth) => {
  return auth;
});

export const getUser = createSelector([authReducerSelector], (auth) => {
  return auth.user;
});

export const getUserDetails = createSelector([authReducerSelector], (auth) => {
  return auth.userDetails;
});

export const getSelectedCity = createSelector([authReducerSelector], (auth) => {
  return auth.selected_city;
});
