import { createSelector } from 'reselect';

const ViewBikeReducerSelector = (state) => state.buyBikes;

export const getFindYourBikeIn = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.findYourBikeIn;
  }
);

export const getProductSlider = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.productSlider;
  }
);

export const getBikeList = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.bikeList;
  }
);

export const getBikeLoader = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.bikesLoader;
  }
);

export const getWishList = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.wishList;
  }
);

export const getApiFilterData = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.apiFilterData;
  }
);

export const getAllFilters = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.filterData;
  }
);

export const getFilterChipsSelector = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.filterChips;
  }
);

export const getFormattedFilters = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.formattedSelectedFilters;
  }
);

export const getYouMayAlsoLike = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.youMayAlsoLike;
  }
);
export const getBikeDetailSelector = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.bikeDetail;
  }
);
export const getBookingInfoSelector = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.bookingInfo;
  }
);
export const getBookingPaymentDataSelector = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.bookingPaymentInfo;
  }
);
export const getVerifyTransactionStatus = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.verifyTransactions;
  }
);

export const getStoredBikeId = createSelector(
  [ViewBikeReducerSelector],
  (ViewBikeReducer) => {
    return ViewBikeReducer.bikeId;
  }
);
