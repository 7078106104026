import { createSelector } from 'reselect';

const SellBikeReducerSelector = (state) => state.sellbike;

export const getBrand = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.brands;
  }
);

export const getModel = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.model;
  }
);

export const getVarient = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.varient;
  }
);

export const getYear = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.years;
  }
);

export const getKMS = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.kmDriven;
  }
);

export const getOwner = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.ownerType;
  }
);

export const getSelectedBrand = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.selectedBrand;
  }
);

export const getMovedTabSelection = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.moveTab;
  }
);

export const getSelectedModel = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.selectedModel;
  }
);

export const getSelectedVarient = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.selectedVarient;
  }
);

export const getSelectedYear = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.selectedYear;
  }
);

export const getSelectedKMS = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.selectedKMS;
  }
);

export const getProvisional = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.provisional_price;
  }
);

export const getSelectedOwner = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.selectedOwner;
  }
);

export const getBikeDataSelector = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.bikeData;
  }
);

export const getSellBikeSearchCriteriaData = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.sellBikeSearchCriteriaData;
  }
);

export const getRecommendedBikeDataSelector = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.recommendedBikeData;
  }
);

export const getSellBikeDocuments = createSelector(
  [SellBikeReducerSelector],
  (sellBikeReducer) => {
    return sellBikeReducer.sellBikeDocuments;
  }
);
