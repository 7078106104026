const styles = (theme) => {
  const secondaryColor = theme?.palette?.secondary;
  return {
    footerWrapper: {
      background: secondaryColor?.footerBgColor,
      color: secondaryColor?.contrastText,
    },

    footerLinks: {
      color: secondaryColor?.contrastText,
      textDecoration: 'none',
      '&:hover' : {
        color: secondaryColor?.contrastText,
      }
    },
    dividerLine: {
      margin: '1rem auto',
      borderTop: '1px solid #E9E9E9',
      maxWidth: '302px',
    },
  };
};
export default styles;
