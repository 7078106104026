import React from 'react';
import { NavLink } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import { headerMenu } from '../../data/menuItems';
import styles from './styles';
import { resetBikeData } from '../../store/sellBike/actions';
import { useDispatch } from 'react-redux';
import { getAllWishlist, resetBikeFilterData } from '../../store/viewBike/actions';
import { Link } from '@mui/material';

export const MenuItemHeader = ({
  handleClick,
  anchorEl,
  open,
  handleClose,
  classes,
  handelWishlist,
}) => {
  const dispatch = useDispatch();
  const location = window && window.location && window.location.pathname;
  // added to handle reset data scenario for sell bke
  const navigateTo = (state) => {
    if (state === '/sell-bike' && location !== '/sell-bike') {
      dispatch(resetBikeData());
      handelWishlist();
    } else if (state === '/view-bike' && location !== '/view-bike') {
      dispatch(resetBikeFilterData());
      handelWishlist();
    }
  };

  return (
    <>
      {headerMenu?.map((currHeader, headerIdx) => {
        return (
          <Box px={3} fontWeight={600}>
            {currHeader.children && currHeader.children.length ? (
              <>
                <Button
                  aria-controls="fade-menu"
                  className={classes.headerMenu}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  {currHeader.name} {!open ? <ArrowDropDownIcon /> : <ArrowDropUp />}
                </Button>

                <Menu
                  className={classes.moreLink}
                  id="fade-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  {currHeader?.children?.map((currChild, childIdx) => {
                    return (
                      <NavLink className={classes.headerLinks} to={currChild?.state}>
                        <MenuItem onClick={handleClose}>
                          <NavLink className={classes.headerLinks} to={currChild?.state}>
                            {currChild?.name}
                          </NavLink>
                        </MenuItem>
                      </NavLink>
                    );
                  })}
                </Menu>
              </>
            ) : (
              <Box onClick={() => navigateTo(currHeader.state)}>
                {/* {currHeader.state === '/view-bike' && location === '/view-bike' ? (
                  <span className="cursor-pointer">{currHeader.name}</span>
                ) : ( */}
                <NavLink className={classes.headerLinks} to={currHeader.state}>
                  {currHeader.name}
                </NavLink>
                {/* )} */}
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default withStyles(styles)(MenuItemHeader);
