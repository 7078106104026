import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { LogoDetails } from '../../data/staticAppData';
import Box from '@material-ui/core/Box';
import styles from './styles';
import { resetBikeData } from '../../store/sellBike/actions';
import { useDispatch } from 'react-redux';
import { getAllWishlist, resetBikeFilterData } from '../../store/viewBike/actions';

export const AppLogo = ({ classes, handelWishlist }) => {
  const dispatch = useDispatch();
  const location = window && window.location && window.location.pathname;
  // added to handle reset data scenario for sell bke
  const navigateTo = () => {
    if(location !== '/'){
      dispatch(resetBikeData());
      dispatch(resetBikeFilterData());
      handelWishlist();
    }
  };

  return (
    <Box
      px={{ xs: 0, sm: 0, md: 3, lg: 3 }}
      pt={{ xs: 2, sm: 2, md: 0, lg: 0 }}
      onClick={navigateTo}
    >
      <NavLink className={classes.headerLinks} to={'/'}>
        <img
          className="img-auto w-135"
          src={LogoDetails.logoHeader}
          alt={LogoDetails.name}
        ></img>
      </NavLink>
    </Box>
  );
};

export default withStyles(styles)(AppLogo);
