import React from 'react';
import { Avatar, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import { Link } from 'react-router-dom';

export const UserData = ({ classes, loginUser, handleCloseModal }) => {
  if (loginUser?.loginStatus) {
    return (
      <Link
        to="/my-account/bookings"
        className="d-flex text-decoration-none"
        onClick={handleCloseModal}
      >
        {loginUser?.profile_picture ? (
          <Avatar
            alt={loginUser?.first_name}
            src={loginUser?.profile_picture}
            className={classes.profileIcon}
          />
        ) : (
          <Avatar className={classes.profileIcon}>
            {loginUser?.first_name?.charAt(0)}
          </Avatar>
        )}
        <Typography className={classes.name}>
          {loginUser?.first_name} {loginUser?.last_name}
        </Typography>
      </Link>
    );
  }
  return (
    <Link
      to="/login"
      className="text-decoration-none"
      onClick={handleCloseModal}
    >
      <Typography variant="h6" className={classes.login}>
        Login
      </Typography>
    </Link>
  );
};

export default withStyles(styles)(UserData);
